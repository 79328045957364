export const searchFilters = [
  {
    label: 'Default',
    value: 'search',
  },
  {
    label: 'Account',
    value: 'account',
  },
  {
    label: 'Transaction',
    value: 'transaction',
  },
  // {
  //   label: 'Swap',
  //   value: 'swap',
  // },
  {
    label: 'Block',
    value: 'block',
  },
];
export const ROUTES = {
  HOME: '/',
  BLOCK: '/block',
  ACCOUNT: '/account',
  TRANSACTION: '/transaction',
  SWAP: '/swap',
};
export const networkFilters = [
  {
    label: 'Chronos',
    value: 'chronos',
  },
];
export const VIEW_TYPE = {
  BLOCK: 'BLOCK',
  SWAP: 'SWAP',
  TRANSACTION: 'TRANSACTION',
  ACCOUNT: 'ACCOUNT',
};

export const chartFilters = [
  {
    label: '1 week',
    value: 7,
  },
  {
    label: '2 weeks',
    value: 14,
  },
];

export const SWAP_STEPS = ['Swap Listing', 'Swap Match', 'Contract Created', 'Contract Confirmed'];

export const STREAM_LIMIT = 15;
export const STREAM_CHUNK_LIMIT = 10;
export const BLOCK_STREAM_UPDATE_TIMER = 2000;
export const BLOCK_STREAM_CHUNK_UPDATE_TIMER = 4000;
export const SWAP_STREAM_UPDATE_TIMER = 25000;
export const TRANSACTION_STREAM_UPDATE_TIMER = 2500;
export const KPI_METRICS_UPDATE_TIMER = 60000;

// Map of decimals for the currencies
export const CURRENCY_OLT = 'OLT';

export const COIN_DECIMAL = {
  OLT: 18,
};
