import * as types from './actionTypes';
import { KPI_API_TYPE, CHRONOS_NETWORK } from '../../config/constants';
import api from '../../config/api';

const getKpiMetricsSuccess = response => ({
  type: types.GET_KPI_METRICS_SUCCESS,
  response,
});
const getKpiMetricsError = error => ({
  type: types.GET_KPI_METRICS_ERROR,
  error,
});

const doKpiMetrics = () => async dispatch => {
  try {
    const response = await api(KPI_API_TYPE, CHRONOS_NETWORK).metricsGet();

    if (response) {
      dispatch(getKpiMetricsSuccess(response));
    }
  } catch (e) {
    const error = {
      msg: e.message,
      stack: e.stack || {},
      err: JSON.stringify(e),
    };
    dispatch(getKpiMetricsError(error));
  }
};

export const getKpiMetrics = doKpiMetrics;

export const clearKpiMetricsInterval = () => (__, getState) => {
  const {
    kpiMetrics: { intervalID },
  } = getState();

  clearInterval(intervalID);
};
