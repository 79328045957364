import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dropdown from './dropdown';

export const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Dropdown),
);
