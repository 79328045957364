import { connect } from 'react-redux';
import AccountInfo from './accountInfo';

export const mapStateToProps = state => ({
  info: state.account.info,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountInfo);
