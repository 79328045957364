import * as types from './actionTypes';
import { searchFilters } from '../../constants/general';

export const defaultState = {
  query: '',
  filter: searchFilters[0].value,
};

const search = (state = defaultState, action) => {
  switch (action.type) {
    case types.UPDATE_SEARCH_RESULT:
      return {
        ...state,
        ...{
          query: action.value,
        },
      };
    case types.UPDATE_FILTER:
      return {
        ...state,
        ...{
          filter: action.value,
        },
      };
    default:
      return state;
  }
};

export default search;
