// File for configuring the websocketrs data stream
import ObservableSocket from '../utils/observable-socket';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import config from '../config/config';

const wsURL =
  process.env.NODE_ENV === 'production'
    ? config.production.networks.socket
    : config.development.networks.socket;

const formatMessage = type =>
  pipe(
    map(x => JSON.parse(x.data)),
    filter(x => x.type === type),
    map(x => x.result),
  );

export function createStreams() {
  const socket = ObservableSocket.create(new WebSocket(`${wsURL}`));
  const block$ = socket.down.pipe(formatMessage('blockHeader'));
  const tx$ = socket.down.pipe(formatMessage('tx'));
  return [block$, tx$];
}
