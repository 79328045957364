export const GET_BLOCK_START = 'BLOCK/GET_BLOCK_START';
export const GET_BLOCK_SUCCESS = 'BLOCK/GET_BLOCK_SUCCESS';
export const GET_BLOCK_ERROR = 'BLOCK/GET_BLOCK_ERROR';

export const GET_BLOCK_TRANSACTIONS_START =
  'BLOCK/GET_BLOCK_TRANSACTIONS_START';
export const GET_BLOCK_TRANSACTIONS_SUCCESS =
  'BLOCK/GET_BLOCK_TRANSACTIONS_SUCCESS';
export const GET_BLOCK_TRANSACTIONS_ERROR =
  'BLOCK/GET_BLOCK_TRANSACTIONS_ERROR';

export const GET_BLOCK_STREAM_START = 'BLOCK/GET_BLOCK_STREAM_START';
export const GET_BLOCK_STREAM_SUCCESS = 'BLOCK/GET_BLOCK_STREAM_SUCCESS';
export const UPDATE_BLOCK_STREAM = 'BLOCK/UPDATE_BLOCK_STREAM';
export const GET_BLOCK_STREAM_ERROR = 'BLOCK/GET_BLOCK_STREAM_ERROR';
export const SET_INTERVAL_ID = 'BLOCK/SET_INTERVAL_ID';

export const NEW_BLOCK_RECEIVE = 'BLOCK/NEW_BLOCK_RECEIVE';

export const GET_LAST_CHUNK_OF_BLOCKS = 'BLOCK/GET_LAST_CHUNK_OF_BLOCKS';
