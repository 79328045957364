import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore.string';
import styles from '../transaction.module.scss';

import Loader from '../../../components/loader/loader';
import Link from '../../../components/link/link.container';
import { ROUTES } from '../../../constants/general';
import { shortenHash } from '../../../utils/formatters';

// @TODO - to move harcoded padding to the styles table
const Content = ({ transaction, isLoading }) => (
  <div className={`${styles.content} `} style={{ paddingLeft: '0.7rem' }}>
    {isLoading ? (
      <Loader message="Loading transaction data..." />
    ) : (
      <Fragment>
        <div className={styles['two-column']}>
          <div className={styles['column-1']}>
            <div className={styles.label}>Type:</div>
            <div className={styles.value}>{_.titleize(transaction.type)}</div>
          </div>
          <div className={styles['column-2']}>
            <div className={styles.label}>Block Height:</div>
            <div className={styles.value}>
              <Link path={`${ROUTES.BLOCK}/${transaction.blockHeight}`}>
                {transaction.blockHeight}
              </Link>
            </div>
          </div>
        </div>
        <div className={styles['two-column']}>
          {transaction.amount && transaction.amount.value && (
            <div className={styles['column-1']}>
              <div className={styles.label}>Value:</div>
              <div className={styles.value}>
                {transaction.amount && transaction.amount.value}
              </div>
            </div>
          )}
          {transaction.fee && (
            <div className={styles['one-column']}>
              <div className={styles.label}>Fee:</div>
              <div className={styles.value}>{transaction.fee.amount}</div>
            </div>
          )}
        </div>
        {transaction.from && (
          <div className={styles['one-column']}>
            <div className={styles.label}>From:</div>
            <div className={styles.value}>
              <div className="is-hidden-desktop">
                <Link path={`${ROUTES.ACCOUNT}/${transaction.from}`}>
                  {shortenHash(transaction.from)}
                </Link>
              </div>
              <div className="is-hidden-touch">
                <Link path={`${ROUTES.ACCOUNT}/${transaction.from}`}>
                  {transaction.from}
                </Link>
              </div>
            </div>
          </div>
        )}
        {transaction.recipients &&
          transaction.recipients.map(recipient => (
            <div className={styles['two-column']} index={recipient.account}>
              <div className={styles['column-1']}>
                <div className={styles.label}>To:</div>
                <div className={styles.value}>
                  <div className="is-hidden-desktop">
                    <Link path={`${ROUTES.ACCOUNT}/${recipient.account}`}>
                      {shortenHash(recipient.account)}
                    </Link>
                  </div>
                  <div className="is-hidden-touch">
                    <Link path={`${ROUTES.ACCOUNT}/${recipient.account}`}>
                      {recipient.account}
                    </Link>
                  </div>
                </div>
              </div>
              <div className={styles['column-2']}>
                <div className={styles.label}>Value:</div>
                <div className={styles.value}>{recipient.amount}</div>
              </div>
            </div>
          ))}
        {/* {transaction.type === 'send' && (
          <div className={styles['two-column']}>
            <div className={styles['column-1']}>
              <div className={styles.label}>To:</div>
              <div className={styles.value}>
                <Link
                  path={`${ROUTES.ACCOUNT}/${
                    transaction.recipients[0].account
                  }`}
                >
                  {transaction.recipients[0].amount}
                </Link>
              </div>
            </div>
          </div>
        )} */}
      </Fragment>
    )}
  </div>
);

Content.propTypes = {
  transaction: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default Content;
