import { connect } from 'react-redux';
import { getSwap } from './actions';
import Swap from './swap';

export const mapStateToProps = state => ({
  swap: state.swap.info.swap,
});
const mapDispatchToProps = {
  getSwap,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Swap);
