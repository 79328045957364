import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../account.module.scss';

import Loader from '../../../../components/loader/loader';

// @TODO - to move harcoded padding to the styles table
const Details = ({ balance, isLoading }) => (
  <div className={styles.content}>
    {isLoading ? (
      <div className={styles.loader}>
        <Loader message="Account data loading..." />
      </div>
    ) : (
      <div className={styles.balance} style={{ paddingLeft: '0.7rem' }}>
        <p>Balance:</p>
        <div className={styles.amount}>
          <p>{`${balance}`}</p>
        </div>
      </div>
    )}
  </div>
);

Details.displayName = 'AccountInfoDetails';
Details.propTypes = {
  balance: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default Details;
