import * as types from './actionTypes';

export const defaultState = {
  isLoading: false,
  data: {},
  error: null,
  intervalID: 0,
};

const kpiMetrics = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_KPI_METRICS_START:
      return {
        ...state,
        ...{
          isLoading: true,
        },
      };
    case types.GET_KPI_METRICS_SUCCESS:
      return {
        ...state,
        ...{
          data: action.response,
          isLoading: false,
        },
      };
    case types.SET_INTERVAL_ID:
      return {
        ...state,
        ...{
          intervalID: action.intervalID,
        },
      };
    case types.GET_KPI_METRICS_ERROR:
      return {
        ...state,
        ...{
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default kpiMetrics;
