import Block from './block.container';
import { ROUTES } from '../../constants/general';

export default [
  {
    name: ROUTES.BLOCK,
    path: `${ROUTES.BLOCK}/:blockId`,
    isSecure: false,
    component: Block,
  },
];
