import { connect } from 'react-redux';
import Info from './info';

export const mapStateToProps = state => ({
  swap: state.swap.info.swap,
  isLoading: state.swap.info.isLoading,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Info);
