import React from 'react';
import PropTypes from 'prop-types';
import styles from '../kpiCards.module.scss';
import Loader from '../../../components/loader/loader';

const CardContent = ({ kpiValue, kpiUnit, isLoading }) => (
  <div className={styles['kpi-inner-container']}>
    {isLoading ? (
      <Loader className={styles['kpi-loader']} />
    ) : (
      <>
        <p className={styles['kpi-value']}>{kpiValue}</p>
        {kpiUnit && <p className={styles['kpi-unit']}>{kpiUnit}</p>}
      </>
    )}
  </div>
);

CardContent.displayName = 'CardContent';
CardContent.propTypes = {
  kpiValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  kpiUnit: PropTypes.oneOf(['/s', 's', 'OLT', '/m']),
  isLoading: PropTypes.bool,
};
CardContent.defaultProps = {
  kpiValue: 0,
};
export default CardContent;
