import React, { Fragment } from 'react';

// Import Components
import Routes from './routes/routes.container';
import Header from './components/header/header.container';
import Footer from './components/footer/footer';
import MaintenancePage from './components/maintenance/maintenance';

// Import Styles
import './app.scss';

const renderApp = () => (
  <Fragment>
    <Header />
    <Routes />
    <Footer />
  </Fragment>
);

const App = () => (
  process.env.MAINTENANCE_MODE === true ? <MaintenancePage /> : renderApp()
);

export default App;
