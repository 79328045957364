import { connect } from 'react-redux';
import { getAccount } from './actions';
import Account from './account';

export const mapStateToProps = () => ({});

const mapDispatchToProps = {
  getAccount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Account);
