import * as types from './actionTypes';

export const defaultState = {
  isDefaultSearchLoading: false,
  error: null,
  response: [],
};

const defaultSearch = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_DEFAULT_SEARCH_START:
      return {
        ...state,
        ...{
          isDefaultSearchLoading: true,
        },
      };
    case types.GET_DEFAULT_SEARCH_SUCCESS:
      return {
        ...state,
        ...{
          ...defaultState,
          response: action.response,
        },
      };
    case types.GET_DEFAULT_SEARCH_ERROR:
      return {
        ...state,
        ...{
          ...defaultState,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default defaultSearch;
