import { connect } from 'react-redux';
import NetworkSwitch from './networkSwitch';

export const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NetworkSwitch);
