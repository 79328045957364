import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltUp, faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';
import Link from '../../../../components/link/link.container';
import styles from '../../swap.module.scss';
import { ROUTES } from '../../../../constants/general';

import { shortenHash } from '../../../../utils/formatters';

const Content = ({ swap }) => (
  <div className={styles.content}>
    <div className={styles.accounts}>
      <p>Account #1:</p>
      <div>&nbsp;</div>
      <p>Account #2:</p>
      <p>Value:</p>
    </div>
    <div className={styles.participants}>
      <p>
        {swap && swap.party ? (
          <Link path={`${ROUTES.ACCOUNT}/${swap.party.address}`}>
            {shortenHash(swap.party.address)}
          </Link>
        ) : (
          ''
        )}
      </p>
      <div>&nbsp;</div>
      <p>
        {swap && swap.counterParty ? (
          <Link path={`${ROUTES.ACCOUNT}/${swap.counterParty.address}`}>
            {shortenHash(swap.counterParty.address)}
          </Link>
        ) : (
          ''
        )}
      </p>
      <p>&nbsp;</p>
    </div>
    <div className={styles.bitcoin}>
      <p>{swap && swap.party ? shortenHash(swap.party.accounts.bitcoin) : ''}</p>
      <div className={styles.arrow}>
        <FontAwesomeIcon icon={faLongArrowAltDown} />
      </div>
      <p>{swap && swap.counterParty ? shortenHash(swap.counterParty.accounts.bitcoin) : ''}</p>
      <p>{swap && swap.amount ? swap.amount.value : ''}</p>
    </div>
    <div className={styles.ethereum}>
      <p>{swap && swap.party ? shortenHash(swap.party.accounts.ethereum) : ''}</p>
      <div className={styles.arrow}>
        <FontAwesomeIcon icon={faLongArrowAltUp} />
      </div>
      <p>{swap && swap.counterParty ? shortenHash(swap.counterParty.accounts.ethereum) : ''}</p>
      <p>{swap && swap.exchange ? swap.exchange.value : ''}</p>
    </div>
  </div>
);

Content.displayName = 'SwapDetailsContent';
Content.propTypes = {
  swap: PropTypes.object.isRequired,
};

export default Content;
