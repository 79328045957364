import React from 'react';
import PropTypes from 'prop-types';
import styles from '../account.module.scss';
import Tab from '../../../components/tab/tab';
import TransactionsTable from './transactions/transactionsTable';
import Loader from '../../../components/loader/loader';

const Transactions = ({
  isLoading,
  transactions,
  activeTab,
  updateActiveTab,
}) => {
  const transactionsArr = transactions.map(txn => [
    txn.hash || '',
    txn.type || '',
    txn.recipients[0].amount || '',
    txn.fee.amount || '',
    txn.from || '',
    txn.recipients[0].account || '',
    txn.blockHeight ? txn.blockHeight : '',
  ]);
  return (
    <div className={`container ${styles.table}`}>
      {isLoading ? (
        <Loader message="Loading block data..." />
      ) : (
        <div>
          <div className={styles.tabs}>
            <Tab
              activeTab={activeTab}
              onTabClick={tab => updateActiveTab(tab)}
              tabs={['Transactions']}
              autoAlign
            />
          </div>
          <TransactionsTable
            // Filter swaps since they are not ready yet
            transactions={transactionsArr}
          />
        </div>
      )}
    </div>
  );
};

Transactions.displayName = 'AccountTransactions';
Transactions.propTypes = {
  isLoading: PropTypes.bool,
  transactions: PropTypes.array,
  activeTab: PropTypes.string,
  updateActiveTab: PropTypes.func,
};

export default Transactions;
