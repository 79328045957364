import { connect } from 'react-redux';
import {
  getBlock,
  getNextBlock,
  getPreviousBlock,
  getInitialBlockStream as getInitialDataStream,
  getLastChunkOfBlocks as getLastChunk,
} from './actions';
import Block from './block';
import WithStreamChunk from '../home/components/stream/streamChunk';

export const mapStateToProps = state => ({
  block: state.block.block,
  dataChunk: state.block.blockChunk,
  isBlockLoading: state.block.isBlockLoading,
  transactions: state.block.transactions,
  isTransactionsLoading: state.block.isTransactionsLoading,
});

const mapDispatchToProps = {
  getBlock,
  getNextBlock,
  getPreviousBlock,
  getInitialDataStream,
  getLastChunk,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithStreamChunk(Block));
