import React from 'react';
import styles from './networkSwitch.module.scss';

// Import Constants
import { networkFilters } from '../../constants/general';

// Import Components
import Dropdown from '../../components/dropdown/dropdown.container';

const NetworkSwitch = () => (
  <div className={styles['network-switch']}>
    <Dropdown
      labelHoverColor="secondary"
      itemHoverColor="secondary"
      labelContainerStyles={styles['filter-label-container']}
      labelStyles={styles['filter-label']}
      options={networkFilters}
      onSelected={() => {}}
    />
  </div>
);

export default NetworkSwitch;
