function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

export var Configuration =
/**
 * parameter for oauth2 security
 * @param name security name
 * @param scopes oauth2 scope
 * @memberof Configuration
 */

/**
 * parameter for basic security
 *
 * @type {string}
 * @memberof Configuration
 */
function Configuration() {
    var param = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    _classCallCheck(this, Configuration);

    if (param.apiKey) {
        this.apiKey = param.apiKey;
    }
    if (param.username) {
        this.username = param.username;
    }
    if (param.password) {
        this.password = param.password;
    }
    if (param.accessToken) {
        this.accessToken = param.accessToken;
    }
    if (param.basePath) {
        this.basePath = param.basePath;
    }
}
/**
 * override base path
 *
 * @type {string}
 * @memberof Configuration
 */

/**
 * parameter for basic security
 *
 * @type {string}
 * @memberof Configuration
 */

/**
 * parameter for apiKey security
 * @param name security name
 * @memberof Configuration
 */
;
/**
 * OneExplorer API
 * Explore
 *
 * OpenAPI spec version: 0.0.3
 * Contact: enrico@oneledger.io
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */