import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Card from '../../components/card/card';
import PaginationTable from '../../components/table/paginationTable';
import Tab from '../../components/tab/tab';
import Loader from '../../components/loader/loader';
import Header from './components/card/header';
import Content from './components/card/content';

import styles from './block.module.scss';

import { buildTransactionsArray } from './helpers';

class Block extends Component {
  constructor(props) {
    super(props);

    const {
      getBlock,
      match: { params },
    } = props;
    getBlock(params);
  }

  shouldComponentUpdate = nextProp => {
    const { dataChunk, match } = this.props;
    const newChunk = nextProp.dataChunk;
    const newMatch = nextProp.match;

    if (match.params.blockId !== newMatch.params.blockId) return true;
    if (!dataChunk.length && !newChunk.length) return false;
    if (!dataChunk.length && newChunk.length) return true;

    return true;
  };

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { blockId: prevId },
      },
    } = prevProps;
    const {
      getBlock,
      dataChunk,
      getInitialDataStream,
      match: { params },
    } = this.props;
    if (prevId !== params.blockId) {
      getBlock(params);
    }

    if (dataChunk.length === 0) {
      getInitialDataStream();
    }
  }

  render() {
    const {
      block,
      transactions,
      isBlockLoading,
      getNextBlock,
      getPreviousBlock,
      dataChunk,
      match,
    } = this.props;

    const { header, blockID, parentBlockID } = block;
    if (isBlockLoading) return <Loader message="Loading data..." />;

    const rows = buildTransactionsArray(transactions);
    const stylesBlock = `section ${styles.block}`;
    const stylesHeaderCard = `container ${styles['header-card']} ${
      styles['transaction-container']
    }`;

    const toShowNext = dataChunk.length
      ? dataChunk[0].height > match.params.blockId && match.params.blockId > 0
      : false;
    const toShowPrev = dataChunk.length
      ? match.params.blockId <= dataChunk[0].height && match.params.blockId > 1
      : false;

    return (
      <section className={stylesBlock}>
        <div className={stylesHeaderCard}>
          <Card
            header={
              <Header
                block={header}
                toShowNextBlockButton={toShowNext}
                toShowPrevBlockButton={toShowPrev}
                getNextBlock={getNextBlock}
                getPreviousBlock={getPreviousBlock}
              />
            }
            content={<Content block={header} blockID={blockID} parentBlockID={parentBlockID} />}
          />
        </div>
        <div className={`container ${styles['transaction-container']}`}>
          <div className={styles['tab-pages']}>
            <Tab activeTab="Transactions" tabs={['Transactions']} onTabClick={() => {}} />
            {transactions.length > 0 ? (
              <Fragment>
                <p className="is-hidden-touch">
                  &nbsp;&nbsp;
                  {`Show transactions 1-${transactions.length} of ${transactions.length}`}
                </p>
                <p className="is-hidden-desktop is-size-7-mobile">
                  {`1-${transactions.length} of ${transactions.length}`}
                </p>
              </Fragment>
            ) : (
              <p className="is-size-7-mobile"> No transactions in block.</p>
            )}
          </div>
          <PaginationTable columns={['Height', 'Fee', 'Hash']} rows={rows} />
        </div>
      </section>
    );
  }
}

Block.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  transactions: PropTypes.array,
  block: PropTypes.object,
  dataChunk: PropTypes.array,
  isBlockLoading: PropTypes.bool,
  getBlock: PropTypes.func,
  getNextBlock: PropTypes.func,
  getPreviousBlock: PropTypes.func,
  getInitialDataStream: PropTypes.func,
};

export default Block;
