import { connect } from 'react-redux';
import KpiCards from './kpiCards';
import { getKpiMetrics } from './actions';

export const mapStateToProps = state => ({
  data: state.kpiMetrics.data,
  isLoading: state.kpiMetrics.isLoading,
  blockChunk: state.block.blockChunk,
});

const mapDispatchToProps = {
  getKpiMetrics,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(KpiCards);
