import { useState, useEffect, useMemo } from 'react';
import Animated from 'animated/lib/targets/react-dom';

export const usePopAnimation = (triggerState, callBack = () => {}) => {
  const [animatedValue] = useState(new Animated.Value(0));

  useEffect(
    () => {
      if (triggerState) {
        Animated.spring(animatedValue, {
          toValue: 1,
        }).start(callBack);
      } else {
        Animated.timing(animatedValue, {
          toValue: 0,
          duration: 200,
        }).start(callBack);
      }
    },
    [triggerState],
  );

  return animatedValue;
};

export const useSpringAnimation = (data, callBack = () => {}) => {
  let animatedValue = new Animated.Value(0);
  let memo = false;
  const lastBlock = data[0] ? data[0] : { height: 0 };

  useMemo(
    () => {
      memo = true;
      Animated.sequence([
        Animated.delay(200),
        Animated.spring(animatedValue, {
          toValue: 1,
        }),
      ]).start(callBack);
    },
    [lastBlock.height ? lastBlock.height : lastBlock.blockHeight],
  );

  if (!memo) {
    animatedValue = new Animated.Value(1);
  }

  return animatedValue;
};
