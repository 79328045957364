import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import NotFound from './notFound';

export const mapStateToProps = () => ({});
const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotFound);
