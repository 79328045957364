import React from 'react';
import PropTypes from 'prop-types';
import styles from '../swap.module.scss';

import Card from '../../../components/card/card';
import SwapInfoContent from './swapInfo/content';
import SwapDetailsHeader from './swapDetails/header';
import SwapDetailsContent from './swapDetails/content';
import Loader from '../../../components/loader/loader';

const Info = ({ swap, isLoading }) => {
  if (isLoading) return <Loader message="Loading swap data..." />;
  return (
    <div className={styles.info}>
      <Card
        header={<Card.Header title="Swap ID" hash={swap.swapKeyHash} columns="two" />}
        content={<SwapInfoContent swap={swap} />}
        className={styles['top-card']}
      />
      <Card
        header={<SwapDetailsHeader />}
        content={<SwapDetailsContent swap={swap} />}
        className={styles['bottom-card']}
      />
    </div>
  );
};

Info.displayName = 'SwapInfo';
Info.propTypes = {
  swap: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default Info;
