import * as types from './actionTypes';

export const defaultState = {
  info: {
    swap: {},
    error: null,
    isLoading: false,
  },
  swapStream: {
    data: [],
    error: null,
    isLoading: false,
    intervalID: 0,
  },
};

const swap = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_SWAP_INFO_START:
      return {
        ...state,
        ...{
          info: {
            ...defaultState.info,
            isLoading: true,
          },
        },
      };
    case types.GET_SWAP_INFO_SUCCESS:
      return {
        ...state,
        ...{
          info: {
            ...defaultState.info,
            swap: action.response,
          },
        },
      };
    case types.GET_SWAP_INFO_ERROR:
      return {
        ...state,
        ...{
          info: {
            ...defaultState.info,
            error: action.error,
          },
        },
      };
    case types.GET_SWAP_STREAM_START:
      return {
        ...state,
        ...{
          swapStream: {
            ...state.swapStream,
            isLoading: true,
          },
        },
      };
    case types.GET_SWAP_STREAM_SUCCESS:
      return {
        ...state,
        ...{
          swapStream: {
            ...state.swapStream,
            data: action.response,
            isLoading: false,
          },
        },
      };
    case types.UPDATE_SWAP_STREAM:
      return {
        ...state,
        ...{
          swapStream: {
            ...state.swapStream,
            data: [action.response, ...state.swapStream.data],
          },
        },
      };
    case types.SET_INTERVAL_ID:
      return {
        ...state,
        ...{
          swapStream: {
            ...state.swapStream,
            intervalID: action.intervalID,
          },
        },
      };
    case types.GET_SWAP_STREAM_ERROR:
      return {
        ...state,
        ...{
          swapStream: {
            ...state.swapStream,
            error: action.error,
          },
        },
      };
    default:
      return state;
  }
};

export default swap;
