function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/* eslint-disable no-use-before-define */
/**
 * OneExplorer API
 * Explore
 *
 * OpenAPI spec version: 0.0.3
 * Contact: enrico@oneledger.io
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

var BASE_PATH = "http://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export var COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|"
};

/**
 *
 * @export
 */


/**
 *
 * @export
 */


/**
 *
 * @export
 */


/**
 * * @export
 * @class RequiredError
 * @extends {Error}
 */
export var RequiredError = function (_Error) {
    _inherits(RequiredError, _Error);

    function RequiredError(field, msg) {
        _classCallCheck(this, RequiredError);

        var _this = _possibleConstructorReturn(this, (RequiredError.__proto__ || Object.getPrototypeOf(RequiredError)).call(this, msg));

        _this.name = "RequiredError";
        return _this;
    }

    return RequiredError;
}(Error);

/**
 * An account
 * @export
 */


/**
 * List of this account's keys
 * @export
 */

/**
 * Properties common to all transactions
 * @export
 */


/**
 * A block
 * @export
 */


/**
 * The block header
 * @export
 */


/**
 * One of the chains supported in OneLedger
 * @export
 * @enum {string}
 */

/**
 * A coin supported on the OneLedger chain
 * @export
 */


/**
 *
 * @export
 */


/**
 *
 * @export
 */


/**
 * Transaction for payments to a validator
 * @export
 */


/**
 * A recipient of a payment transaction
 * @export
 */


/**
 * Transaction for registering an account with a global name
 * @export
 */


/**
 * Transaction for sending an amount
 * @export
 */


/**
 * SwapExchange issued to swap stuff
 * @export
 */


/**
 * SwapInit is used to initialize a swap transaction
 * @export
 */


/**
 * Initiator of a transaction
 * @export
 */


/**
 * A swap transaction
 * @export
 */


/**
 * Verifies the completion of the initial swap
 * @export
 */


/**
 * A transaction
 * @export
 */


/**
 * AccountsApi - fetch parameter creator
 * @export
 */
export var AccountsApiFetchParamCreator = function AccountsApiFetchParamCreator(configuration) {
    return {
        /**
         *
         * @throws {RequiredError}
         */
        accountsAddressGet: function accountsAddressGet(address, options) {
            // verify required parameter 'address' is not null or undefined
            if (address === null || address === undefined) {
                throw new RequiredError('address', 'Required parameter address was null or undefined when calling accountsAddressGet.');
            }
            var localVarPath = "/accounts/{address}".replace("{" + "address" + "}", encodeURIComponent(String(address)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({}, { method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions
            };
        },

        /**
         *
         * @throws {RequiredError}
         */
        accountsAddressTxsGet: function accountsAddressTxsGet(address, page, options) {
            // verify required parameter 'address' is not null or undefined
            if (address === null || address === undefined) {
                throw new RequiredError('address', 'Required parameter address was null or undefined when calling accountsAddressTxsGet.');
            }
            var localVarPath = "/accounts/{address}/txs".replace("{" + "address" + "}", encodeURIComponent(String(address)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({}, { method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions
            };
        }
    };
};

/**
 * AccountsApi - factory function to inject configuration
 * @export
 */
export var AccountsApi = function AccountsApi(configuration) {
    var fetch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : portableFetch;

    var basePath = configuration && configuration.basePath || BASE_PATH;
    return {
        /**
         *
         * @throws {RequiredError}
         */
        accountsAddressGet: function accountsAddressGet(address) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            var localVarFetchArgs = AccountsApiFetchParamCreator(configuration).accountsAddressGet(address, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },

        /**
         *
         * @throws {RequiredError}
         */
        accountsAddressTxsGet: function accountsAddressTxsGet(address, page) {
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            var localVarFetchArgs = AccountsApiFetchParamCreator(configuration).accountsAddressTxsGet(address, page, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        }
    };
};

/**
 * BlockchainApi - fetch parameter creator
 * @export
 */
export var BlockchainApiFetchParamCreator = function BlockchainApiFetchParamCreator(configuration) {
    return {
        /**
         *
         * @throws {RequiredError}
         */
        metricsGet: function metricsGet(options) {
            var localVarPath = "/metrics";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({}, { method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions
            };
        },

        /**
         *
         * @throws {RequiredError}
         */
        wsGet: function wsGet(options) {
            var localVarPath = "/ws";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({}, { method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions
            };
        }
    };
};

/**
 * BlockchainApi - factory function to inject configuration
 * @export
 */
export var BlockchainApi = function BlockchainApi(configuration) {
    var fetch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : portableFetch;

    var basePath = configuration && configuration.basePath || BASE_PATH;
    return {
        /**
         *
         * @throws {RequiredError}
         */
        metricsGet: function metricsGet() {
            var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            var localVarFetchArgs = BlockchainApiFetchParamCreator(configuration).metricsGet(options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },

        /**
         *
         * @throws {RequiredError}
         */
        wsGet: function wsGet() {
            var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            var localVarFetchArgs = BlockchainApiFetchParamCreator(configuration).wsGet(options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        }
    };
};

/**
 * BlocksApi - fetch parameter creator
 * @export
 */
export var BlocksApiFetchParamCreator = function BlocksApiFetchParamCreator(configuration) {
    return {
        /**
         *
         * @throws {RequiredError}
         */
        blocksBlockHeightGet: function blocksBlockHeightGet(blockHeight, options) {
            // verify required parameter 'blockHeight' is not null or undefined
            if (blockHeight === null || blockHeight === undefined) {
                throw new RequiredError('blockHeight', 'Required parameter blockHeight was null or undefined when calling blocksBlockHeightGet.');
            }
            var localVarPath = "/blocks/{blockHeight}".replace("{" + "blockHeight" + "}", encodeURIComponent(String(blockHeight)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({}, { method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions
            };
        },

        /**
         *
         * @throws {RequiredError}
         */
        blocksGet: function blocksGet(beforeHeight, limit, options) {
            var localVarPath = "/blocks";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({}, { method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};

            if (beforeHeight !== undefined) {
                localVarQueryParameter['beforeHeight'] = beforeHeight;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions
            };
        }
    };
};

/**
 * BlocksApi - factory function to inject configuration
 * @export
 */
export var BlocksApi = function BlocksApi(configuration) {
    var fetch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : portableFetch;

    var basePath = configuration && configuration.basePath || BASE_PATH;
    return {
        /**
         *
         * @throws {RequiredError}
         */
        blocksBlockHeightGet: function blocksBlockHeightGet(blockHeight) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            var localVarFetchArgs = BlocksApiFetchParamCreator(configuration).blocksBlockHeightGet(blockHeight, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },

        /**
         *
         * @throws {RequiredError}
         */
        blocksGet: function blocksGet(beforeHeight, limit) {
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            var localVarFetchArgs = BlocksApiFetchParamCreator(configuration).blocksGet(beforeHeight, limit, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        }
    };
};

/**
 * TransactionsApi - fetch parameter creator
 * @export
 */
export var TransactionsApiFetchParamCreator = function TransactionsApiFetchParamCreator(configuration) {
    return {
        /**
         *
         * @throws {RequiredError}
         */
        swapsLatestGet: function swapsLatestGet(options) {
            var localVarPath = "/swaps/latest";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({}, { method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions
            };
        },

        /**
         *
         * @throws {RequiredError}
         */
        swapsSwapKeyHashGet: function swapsSwapKeyHashGet(swapKeyHash, options) {
            // verify required parameter 'swapKeyHash' is not null or undefined
            if (swapKeyHash === null || swapKeyHash === undefined) {
                throw new RequiredError('swapKeyHash', 'Required parameter swapKeyHash was null or undefined when calling swapsSwapKeyHashGet.');
            }
            var localVarPath = "/swaps/{swapKeyHash}".replace("{" + "swapKeyHash" + "}", encodeURIComponent(String(swapKeyHash)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({}, { method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions
            };
        },

        /**
         *
         * @throws {RequiredError}
         */
        txsGet: function txsGet(ids, options) {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids', 'Required parameter ids was null or undefined when calling txsGet.');
            }
            var localVarPath = "/txs";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({}, { method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions
            };
        },

        /**
         *
         * @throws {RequiredError}
         */
        txsLatestGet: function txsLatestGet(options) {
            var localVarPath = "/txs/latest";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({}, { method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions
            };
        },

        /**
         *
         * @throws {RequiredError}
         */
        txsTxIDGet: function txsTxIDGet(txID, options) {
            // verify required parameter 'txID' is not null or undefined
            if (txID === null || txID === undefined) {
                throw new RequiredError('txID', 'Required parameter txID was null or undefined when calling txsTxIDGet.');
            }
            var localVarPath = "/txs/{txID}".replace("{" + "txID" + "}", encodeURIComponent(String(txID)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({}, { method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions
            };
        }
    };
};

/**
 * TransactionsApi - factory function to inject configuration
 * @export
 */
export var TransactionsApi = function TransactionsApi(configuration) {
    var fetch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : portableFetch;

    var basePath = configuration && configuration.basePath || BASE_PATH;
    return {
        /**
         *
         * @throws {RequiredError}
         */
        swapsLatestGet: function swapsLatestGet() {
            var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            var localVarFetchArgs = TransactionsApiFetchParamCreator(configuration).swapsLatestGet(options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },

        /**
         *
         * @throws {RequiredError}
         */
        swapsSwapKeyHashGet: function swapsSwapKeyHashGet(swapKeyHash) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            var localVarFetchArgs = TransactionsApiFetchParamCreator(configuration).swapsSwapKeyHashGet(swapKeyHash, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },

        /**
         *
         * @throws {RequiredError}
         */
        txsGet: function txsGet(ids) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            var localVarFetchArgs = TransactionsApiFetchParamCreator(configuration).txsGet(ids, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },

        /**
         *
         * @throws {RequiredError}
         */
        txsLatestGet: function txsLatestGet() {
            var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            var localVarFetchArgs = TransactionsApiFetchParamCreator(configuration).txsLatestGet(options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        },

        /**
         *
         * @throws {RequiredError}
         */
        txsTxIDGet: function txsTxIDGet(txID) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            var localVarFetchArgs = TransactionsApiFetchParamCreator(configuration).txsTxIDGet(txID, options);
            return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        }
    };
};
