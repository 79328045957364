import Swap from './swap.container';
import { ROUTES } from '../../constants/general';

export default [
  {
    name: ROUTES.SWAP,
    path: `${ROUTES.SWAP}/:swapKeyHash`,
    isSecure: false,
    component: Swap,
  },
];
