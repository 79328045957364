import moment from 'moment';

export const isNumber = value => !Number.isNaN(parseFloat(value));

export const shortenHash = address => {
  if (address === undefined) return '';
  const prefix = address.substring(0, 6);
  const suffix = address.substring(address.length - 4, address.length);
  return `${prefix}...${suffix}`;
};

export const dateFormatter = date =>
  moment.utc(date).format('YYYY-MM-DD HH:MM:SS z');

export const amountFormatter = amount =>
  Number(amount)
    .toFixed(2)
    .toString();

export const ageFormatter = date => {
  const age = Math.ceil(moment.utc().diff(moment.utc(date), 'days'));
  return `${age} day(s)`;
};

export const convertNumberToString = (num, scale = 2) => {
  if (!isNumber(num)) return '0';

  // comment by Oleks <oleksandr@oneledger.io>
  // main idea here is to address a problem of
  // long number precission for cases like x.23849487579
  // basically it is smth like I have in my gist
  // https://gist.github.com/okovalov/11af907e7267faa62452cdaf50e5e26d
  if (!`${num}`.includes('e')) {
    return (+`${Math.round(`${num}e+${scale}`)}e-${scale}`).toString();
  }

  const arr = `${num}`.split('e');
  let sig = '';
  if (+arr[1] + scale > 0) {
    sig = '+';
  }
  const i = `${+arr[0]}e${sig}${+arr[1] + scale}`;
  const j = Math.round(i);
  const k = (+`${j}e-${scale}`).toString();

  return k;
};
