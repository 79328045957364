import React, { Fragment } from 'react';
import './footer.scss';

import logo from '../../img/ol_logo.png';

const Footer = () => (
  <Fragment>
    <footer className="footer sticks-to-bottom">

      <div className="content">
        <div className="adress-container has-text-weight-semibold has-text-centered-touch">
          <div className="columns">
            <div className="column ">
              <p className="  has-text-grey-light address-row is-size-6">Suite 112, 1st Floor One Welches, Welches</p>
              <p className="  has-text-grey-light address-row is-size-6">St. Thomas Barbados, BB22025</p>
            </div>
            <div className="column has-text-right-desktop is-hidden-touch">
              <div className="content ">
                <p className="contact-us-row ">
                  <a href="https://t.me/oneledger" target="_blank" rel="noopener noreferrer">
                    <span className="fab fa-telegram contact-us-item" />
                  </a>
                  <a href="https://github.com/Oneledger/" target="_blank" rel="noopener noreferrer">
                    <span className="fab fa-github contact-us-item" />
                  </a>
                  <a href="https://medium.com/@OneLedger" target="_blank" rel="noopener noreferrer">
                    <span className="fab fa-medium-m contact-us-item" />
                  </a>
                  <a href="https://www.linkedin.com/company/oneledger-innovative-tech-inc/" target="_blank" rel="noopener noreferrer">
                    <span className="fab fa-linkedin-in contact-us-item" />
                  </a>
                  <a href="https://twitter.com/OneLedgerTech" target="_blank" rel="noopener noreferrer">
                    <span className="fab fa-twitter contact-us-item" />
                  </a>
                  <a href="mailto:hello@oneledger.io" target="_blank" rel="noopener noreferrer">
                    <span className="fas fa-envelope contact-us-item" />
                  </a>
                  <a href="https://www.youtube.com/channel/UCqazyMCaD7lH-IBZrb33WPg" target="_blank" rel="noopener noreferrer">
                    <span className="fab fa-youtube contact-us-item" />
                  </a>
                  <a href="https://reddit.com/r/Oneledger" target="_blank" rel="noopener noreferrer">
                    <span className="fab fa-reddit contact-us-item" />
                  </a>
                  <a href="https://www.facebook.com/OneLedgerTech/" target="_blank" rel="noopener noreferrer">
                    <span className="fab fa-facebook-f contact-us-item" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="columns is-vcentered">
          <div className="column is-hidden-touch">
            <img src={logo} width="112" alt="OneLedger" height="28" />
          </div>
          <div className="column ">
            <p className="has-text-grey-light  has-text-right-desktop has-text-centered-touch is-size-8 is-size-6-touch has-text-weight-medium">
              Copyright © 2019 OneLedger Technology Inc. All Rights Reserved.
            </p>
          </div>
          <div className="column is-hidden-desktop">
            <div className="content has-text-centered-touch">
              <p className="contact-us-row ">
                <a href="https://t.me/oneledger" target="_blank" rel="noopener noreferrer">
                  <span className="fab fa-telegram contact-us-item" />
                </a>
                <a href="https://github.com/Oneledger/" target="_blank" rel="noopener noreferrer">
                  <span className="fab fa-github contact-us-item" />
                </a>
                <a href="https://medium.com/@OneLedger" target="_blank" rel="noopener noreferrer">
                  <span className="fab fa-medium-m contact-us-item" />
                </a>
                <a href="https://www.linkedin.com/company/oneledger-innovative-tech-inc/" target="_blank" rel="noopener noreferrer">
                  <span className="fab fa-linkedin-in contact-us-item" />
                </a>
                <a href="https://twitter.com/OneLedgerTech" target="_blank" rel="noopener noreferrer">
                  <span className="fab fa-twitter contact-us-item" />
                </a>
                <a href="mailto:hello@oneledger.io" target="_blank" rel="noopener noreferrer">
                  <span className="fas fa-envelope contact-us-item" />
                </a>
                <a href="https://www.youtube.com/channel/UCqazyMCaD7lH-IBZrb33WPg" target="_blank" rel="noopener noreferrer">
                  <span className="fab fa-youtube contact-us-item" />
                </a>
                <a href="https://reddit.com/r/Oneledger" target="_blank" rel="noopener noreferrer">
                  <span className="fab fa-reddit contact-us-item" />
                </a>
                <a href="https://www.facebook.com/OneLedgerTech/" target="_blank" rel="noopener noreferrer">
                  <span className="fab fa-facebook-f contact-us-item" />
                </a>
              </p>
            </div>

          </div>
        </div>
      </div>

    </footer>
  </Fragment>
);

export default Footer;
