import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StreamDisplay from '../streamDisplay';
import { VIEW_TYPE } from '../../../../../constants/general';

class BlockStream extends Component {
  shouldComponentUpdate = nextProp => {
    const { dataChunk } = this.props;
    const newChunk = nextProp.dataChunk;

    if (!dataChunk.length && !newChunk.length) return false;
    if (!dataChunk.length && newChunk.length) return true;

    return dataChunk[0].height !== newChunk[0].height;
  }

  render() {
    const { dataChunk } = this.props;

    const streamData =
      dataChunk.length === 0
        ? []
        : [{ height: dataChunk[0].height + 1, validating: true }, ...dataChunk];

    return (
      <StreamDisplay
        title="Block Stream"
        data={streamData}
        type={VIEW_TYPE.BLOCK}
      />
    );
  }
}

BlockStream.propTypes = {
  dataChunk: PropTypes.array,
};

export default BlockStream;
