import { configureStore } from './configureStore';
import { createStreams } from './sockets';
import { getInitialBlockStream, receiveBlockHeader } from '../views/block/actions';
import { receiveTx, getInitialTransactionStream } from '../views/transaction/actions';

const redux = configureStore();
const { store } = redux;

// Get initial blocks
store.dispatch(getInitialBlockStream);
store.dispatch(getInitialTransactionStream);

const [block$, tx$] = createStreams();

block$.subscribe(
  blockHeader => {
    if (
      store
        .getState()
        .block.blockStream.data.findIndex(x => (x.height === blockHeader.height) === -1)
    ) {
      store.dispatch(receiveBlockHeader(blockHeader));
    }
  },
  err => {
    console.error(err);
  },
);

tx$.subscribe(
  tx => {
    store.dispatch(receiveTx(tx));
  },
  err => {
    console.error(err);
  },
);

// Have access to the store dispatch here, instantiate data stream:
// (1) Begin the stream inside websockets.js
// (2) Expose the observables here
// (3) Define next() calls here which dispatch stuff to UPDATE_BLOCK

export default redux;
