import React from 'react';
import PropTypes from 'prop-types';
import Animated from 'animated/lib/targets/react-dom';
import Link from '../../../../../components/link/link.container';
import { ROUTES } from '../../../../../constants/general';

// Import Assets
import logoSmall from '../../../../../img/logo-small.svg';

// Import styles
import styles from './blockStreamCard.module.scss';

const BlockStreamCard = ({ style, item, index, ...restProps }) => (
  <div className={styles['block-stream-card-container']} {...restProps}>
    <Animated.div className={styles['stream-card']} style={style}>
      {index === 0 ? (
        <img
          src={logoSmall}
          className={styles.loadingIcon}
          alt="logo-loading"
        />
      ) : (
        <img className={styles.logo} src={logoSmall} alt="logo-small" />
      )}
      <div>
        <p className={styles.label}>Block Height:</p>
        {index === 0 ? (
          <p className={styles.value}>{item.height}</p>
        ) : (
          <Link path={`${ROUTES.BLOCK}/${item.height}`}>
            <p className={styles.value}>{item.height}</p>
          </Link>
        )}
      </div>

      <div className={styles['validation-content']}>
        {index === 0 ? (
          <p className={styles.label}>Validating...</p>
        ) : (
          <p className={styles.value}>
            {`${item.numTxs} ${
              item.numTxs === 0 ? 'transaction' : 'transactions'
            }`}
          </p>
        )}
      </div>
    </Animated.div>

  </div>
);

BlockStreamCard.propTypes = {
  style: PropTypes.object,
  item: PropTypes.object,
  index: PropTypes.number,
};

export default BlockStreamCard;
