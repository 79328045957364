import Transaction from './transaction.container';
import { ROUTES } from '../../constants/general';

export default [
  {
    name: ROUTES.TRANSACTION,
    path: `${ROUTES.TRANSACTION}/:hash`,
    isSecure: false,
    component: Transaction,
  },
];
