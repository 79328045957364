import React from 'react';
import styles from './home.module.scss';

// Import Components
import BlockStream from './components/stream/blockStream/blockStream.container';
import TransactionStream from './components/stream/transactionStream/transactionStream.container';
import KpiCards from '../../state/kpiCards/kpiCards.container';

const Home = () => {
  const sectionContainer = `section ${styles.home}`;
  const kpiContainer = `container ${styles.kpiContainer}`;
  const blockContainer = `container ${styles.blockContainer}`;
  const transactionContainer = `container ${styles.transactionContainer}`;

  return (
    <section className={sectionContainer}>
      <div className={kpiContainer}>
        <KpiCards />
      </div>
      <div className={blockContainer}>
        <BlockStream />
      </div>
      <div className={transactionContainer}>
        <TransactionStream />
      </div>
      {/* <div className={styles.stream}> */}
      {/* <BlockStream /> */}
      {/* <TransactionStream /> */}
      {/* </div> */}
    </section>
  );
};

Home.displayName = 'Home';

export default Home;
