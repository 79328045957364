import * as types from './actionTypes';
import api from '../../config/api';
import { SWAPS_API_TYPE, CHRONOS_NETWORK } from '../../config/constants';
import { STREAM_LIMIT, SWAP_STREAM_UPDATE_TIMER } from '../../constants/general';

const getSwapInfoStart = () => ({
  type: types.GET_SWAP_INFO_START,
});
const getSwapInfoSuccess = response => ({
  type: types.GET_SWAP_INFO_SUCCESS,
  response,
});
const getSwapInfoError = error => ({
  type: types.GET_SWAP_INFO_ERROR,
  error,
});

const getSwapStatus = swapTxs => {
  const numSwapTxs = swapTxs.length;

  if (numSwapTxs > 0 && numSwapTxs <= 2) {
    return 1;
  }
  if (numSwapTxs >= 3 && numSwapTxs < 5) {
    return 2;
  }
  if (numSwapTxs >= 5 && numSwapTxs <= 7) {
    return 3;
  }
  if (numSwapTxs > 7) {
    return 4;
  }

  return 0;
};

const getSwapInfo = hash => async dispatch => {
  dispatch(getSwapInfoStart());
  try {
    const response = await api(SWAPS_API_TYPE, CHRONOS_NETWORK).swapsSwapKeyHashGet(hash);
    if (response) {
      const swapStatus = getSwapStatus(response);
      const result = {
        ...response[0],
        ...swapStatus,
      };
      return dispatch(getSwapInfoSuccess(result));
    }
    return dispatch(getSwapInfoError());
  } catch (e) {
    const error = {
      msg: e.message,
      stack: e.stack || {},
      err: JSON.stringify(e),
    };
    return dispatch(getSwapInfoError(error));
  }
};

export const getSwap = fallback => async (dispatch) => {
  const { swapKeyHash } = fallback;
  return dispatch(getSwapInfo(swapKeyHash));
};

const getSwapStreamStart = () => ({
  type: types.GET_SWAP_STREAM_START,
});
const getSwapStreamSuccess = response => ({
  type: types.GET_SWAP_STREAM_SUCCESS,
  response,
});
const updateSwapStream = response => ({
  type: types.UPDATE_SWAP_STREAM,
  response,
});
const setSwapStreamIntervalID = intervalID => ({
  type: types.SET_INTERVAL_ID,
  intervalID,
});
const getSwapStreamError = error => ({
  type: types.GET_SWAP_STREAM_ERROR,
  error,
});

export const getInitialSwapStream = () => async dispatch => {
  dispatch(getSwapStreamStart());
  try {
    // End-point Missing from API
    const response = await api(SWAPS_API_TYPE, CHRONOS_NETWORK).swapsGet('', STREAM_LIMIT);
    if (response) {
      return dispatch(getSwapStreamSuccess(response.reverse()));
    }
    return dispatch(getSwapStreamError());
  } catch (e) {
    const error = {
      msg: e.message,
      stack: e.stack || {},
      err: JSON.stringify(e),
    };
    return dispatch(getSwapStreamError(error));
  }
};

export const getUpdatedSwapStream = () => async dispatch => {
  const intervalID = setInterval(async () => {
    try {
      // End-point Missing from API
      const response = await api(SWAP_API_TYPE, CHRONOS_NETWORK).swapsSwapKeyHashGet('0');
      if (response) {
        dispatch(updateSwapStream(response.header));
      }
    } catch (e) {
      const error = {
        msg: e.message,
        stack: e.stack || {},
        err: JSON.stringify(e),
      };
      dispatch(getSwapStreamError(error));
    }
  }, SWAP_STREAM_UPDATE_TIMER);
  return dispatch(setSwapStreamIntervalID(intervalID));
};

export const clearSwapstreamInterval = () => (_, getState) => {
  const {
    swap: {
      swapStream: { intervalID },
    },
  } = getState();

  clearInterval(intervalID);
};
