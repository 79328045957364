import { connect } from 'react-redux';
import { getTransaction } from './actions';
import Transaction from './transaction';

export const mapStateToProps = state => ({
  transaction: state.transaction.txn,
  isLoading: state.transaction.isLoading,
});
const mapDispatchToProps = {
  getTransaction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Transaction);
