import React from 'react';
import PropTypes from 'prop-types';
import Steps, { Step } from 'rc-steps';
import { SWAP_STEPS } from '../../../constants/general';
import styles from '../swap.module.scss';

import './rc-steps.css';

const SwapSteps = ({ current }) => (
  <div className={styles.steps}>
    <Steps current={current} direction="horizontal" labelPlacement="vertical">
      {SWAP_STEPS.map((step, index) => (
        <Step title={step} icon={index + 1} icons={{ finish: index + 1 }} key={index} />
      ))}
    </Steps>
  </div>
);

SwapSteps.propTypes = {
  current: PropTypes.number.isRequired,
};

export default SwapSteps;
