import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../swap.module.scss';

import { dateFormatter } from '../../../../utils/formatters';

const Content = ({ swap }) => (
  <div className={styles.content}>
    <div className={styles.nonce}>
      <div className={styles.title}>Fee</div>
      <div className={styles.value}>{`${swap.fee}`}</div>
    </div>
    <div className={styles.timestamp}>
      <div className={styles.title}>Timestamp</div>
      <div className={styles.value}>{dateFormatter(swap.timestamp)}</div>
    </div>
  </div>
);

Content.displayName = 'SwapInfoContent';
Content.propTypes = {
  swap: PropTypes.object.isRequired,
};

export default Content;
