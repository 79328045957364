import * as types from './actionTypes';
import { STREAM_CHUNK_LIMIT, STREAM_LIMIT } from '../../constants/general';

export const defaultState = {
  isBlockLoading: false,
  isTransactionsLoading: false,
  block: {},
  transactions: [],
  error: null,
  blockChunk: [],
  blockStream: {
    data: [],
    error: null,
    isLoading: false,
    intervalID: 0,
  },
};

const block = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_BLOCK_START:
      return {
        ...state,
        ...{
          isBlockLoading: true,
        },
      };
    case types.GET_BLOCK_SUCCESS:
      return {
        ...defaultState,
        ...{
          block: action.block,
        },
      };
    case types.GET_BLOCK_ERROR:
      return {
        ...defaultState,
        ...{
          error: action.error,
        },
      };
    case types.GET_BLOCK_TRANSACTIONS_START:
      return {
        ...state,
        ...{
          isTransactionsLoading: true,
        },
      };
    case types.GET_BLOCK_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        ...{
          isTransactionsLoading: false,
          transactions: action.transactions,
        },
      };
    case types.GET_BLOCK_TRANSACTIONS_ERROR:
      return {
        ...defaultState,
        ...{
          error: action.error,
        },
      };
    case types.GET_BLOCK_STREAM_START:
      return {
        ...state,
        ...{
          blockStream: {
            ...state.blockStream,
            isLoading: true,
          },
        },
      };
    case types.GET_BLOCK_STREAM_SUCCESS: {
      return {
        ...state,
        ...{
          blockStream: {
            ...state.blockStream,
            data: action.response,
            isLoading: false,
          },
        },
      };
    }
    case types.UPDATE_BLOCK_STREAM: {
      return {
        ...state,
        ...{
          blockStream: {
            ...state.blockStream,
            data: action.response,
          },
        },
      };
    }
    case types.SET_INTERVAL_ID:
      return {
        ...state,
        ...{
          blockStream: {
            ...state.blockStream,
            intervalID: action.intervalID,
          },
        },
      };
    case types.GET_BLOCK_STREAM_ERROR:
      return {
        ...state,
        ...{
          blockStream: {
            ...state.blockStream,
            error: action.error,
          },
        },
      };
    case types.GET_LAST_CHUNK_OF_BLOCKS: {
      const tmpBlockChunk = state.blockStream.data.slice(0, STREAM_CHUNK_LIMIT);
      const blockChunk = tmpBlockChunk.length > 0 ? tmpBlockChunk : state.blockChunk;

      return {
        ...state,
        blockChunk,
      };
    }
    case types.NEW_BLOCK_RECEIVE: {
      // This is based off the blockX code
      const { data } = state.blockStream;
      const { response } = action;

      // Sometimes the stream includes the block we get on getInitialBlockStream,
      // ignore if this is the case
      let nextData;
      if (data[0] && data[0].height === response.height) {
        nextData = data;
      } else {
        nextData = [response, ...data].slice(0, STREAM_LIMIT);
      }

      return {
        ...state,
        ...{
          blockStream: {
            ...state.blockStream,
            data: nextData,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default block;
