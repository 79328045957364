export const GET_TRANSACTION_START = 'TRANSACTION/GET_TRANSACTION_START';
export const GET_TRANSACTION_SUCCESS = 'TRANSACTION/GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_ERROR = 'TRANSACTION/GET_TRANSACTION_ERROR';

export const GET_TRANSACTION_STREAM_START =
  'TRANSACTION/GET_TRANSACTION_STREAM_START';
export const GET_TRANSACTION_STREAM_SUCCESS =
  'TRANSACTION/GET_TRANSACTION_STREAM_SUCCESS';
export const UPDATE_TRANSACTION_STREAM =
  'TRANSACTION/UPDATE_TRANSACTION_STREAM';
export const GET_TRANSACTION_STREAM_ERROR =
  'TRANSACTION/GET_TRANSACTION_STREAM_ERROR';
export const SET_INTERVAL_ID = 'TRANSACTION/SET_INTERVAL_ID';
export const NEW_TRANSACTION_RECEIVE = 'TRANSACTION/NEW_TRANSACTION_RECEIVE';
export const GET_LAST_CHUNK_OF_TRANSACTIONS = 'TRANSACTION/GET_LAST_CHUNK_OF_TRANSACTIONS';
