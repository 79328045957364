export default {
  development: {
    networks: {
      chronos: 'http://localhost:8001',
      socket: 'ws://localhost:8001/ws',
    },
  },
  production: {
    networks: {
      chronos: 'https://explorer.oneledger.network',
      socket: 'wss://explorer.oneledger.network/ws',
    },
  },
};
