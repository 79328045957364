import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';

import styles from '../../block.module.scss';

import { dateFormatter, shortenHash } from '../../../../utils/formatters';

const Content = ({ block, blockID, parentBlockID }) => (
  <div className={`columns ${styles.content}`}>
    <div className="column">

      <div className={` ${styles.flexed} ${styles.validator}`}>
        <div className={`  ${styles.label}`}>
          Parent Hash&emsp;
          <CopyToClipboard text={parentBlockID}>
            <FontAwesomeIcon
              className={styles.copy}
              icon={faCopy}
              data-tip="Click to copy!"
              data-for="copy-parent-id"
            />
          </CopyToClipboard>
          <ReactTooltip id="copy-parent-id" effect="solid" />
        </div>
        <div className={`  ${styles.value} is-size-7-touch`}>
          {parentBlockID && shortenHash(parentBlockID)}
        </div>
      </div>
      <div className={` ${styles.flexed} ${styles.hashes}`}>
        <div className={` ${styles.label}`}>
          Block Hash&emsp;
          <CopyToClipboard text={blockID}>
            <FontAwesomeIcon
              className={styles.copy}
              icon={faCopy}

              data-tip="Click to copy!"
              data-for="copy-block-id"
            />
          </CopyToClipboard>
          <ReactTooltip id="copy-block-id" effect="solid" />
        </div>
        <div className={`${styles.value} is-size-7-touch`}>
          <p>{blockID && shortenHash(blockID)}</p>
        </div>
      </div>
    </div>
    <div className={`column ${styles.flexedColumn} `}>
      <div className={` ${styles.flexed} ${styles['block-size']}`}>
        <div className={styles.label}>Block Size</div>
        <div className={`${styles.value} is-size-7-touch`}>
          {block && `${parseFloat(block.blockSize / 1000, 10)} kb`}
        </div>
      </div>
      <div className={` ${styles.flexed} ${styles.fee}`}>
        <div className={styles.label}>Number of Transactions</div>
        <div className={`${styles.value} is-size-7-touch`}>{block && block.numTxs}</div>
      </div>
    </div>
    <div className={`column  ${styles.timestamp}`}>
      <div className={styles.label}>Timestamp</div>
      <div className={`has-text-centered ${styles.value} is-size-7-touch`}>{block && dateFormatter(block.time)}</div>
    </div>
  </div>
);

Content.propTypes = {
  block: PropTypes.object,
  blockID: PropTypes.string,
  parentBlockID: PropTypes.string,
};

export default Content;
