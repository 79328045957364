import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Routes from './routes';

// Import Routes
import home from '../views/home/home.routes';
import swap from '../views/swap/swap.routes';
import account from '../views/account/account.routes';
import transaction from '../views/transaction/transaction.routes';
import block from '../views/block/block.routes';
import search from '../views/search/search.routes';

export const mapStateToProps = () => ({
  routes: [...home, ...swap, ...account, ...transaction, ...block, ...search],
});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Routes),
);
