import React from 'react';
import styles from '../../swap.module.scss';

const Header = () => (
  <div className={styles.container}>
    <div className={styles.accounts}>&nbsp;</div>
    <div className={styles.participants}>Participants</div>
    <div className={styles.bitcoin}>Bitcoin</div>
    <div className={styles.ethereum}>Ethereum</div>
  </div>
);

export default Header;
