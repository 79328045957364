import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StreamDisplay from '../streamDisplay';
import { VIEW_TYPE } from '../../../../../constants/general';

class TransactionStream extends Component {
  shouldComponentUpdate = nextProp => {
    const { dataChunk } = this.props;
    const newChunk = nextProp.dataChunk;

    if (!dataChunk.length && !newChunk.length) return false;
    if (!dataChunk.length && newChunk.length) return true;

    return dataChunk[0].blockHeight !== newChunk[0].blockHeight;
  }

  render() {
    const { dataChunk } = this.props;

    return (
      <StreamDisplay
        title="Transaction Stream"
        data={dataChunk}
        type={VIEW_TYPE.TRANSACTION}
      />
    );
  }
}

TransactionStream.propTypes = {
  dataChunk: PropTypes.array,
};

export default TransactionStream;
