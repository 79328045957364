import * as types from './actionTypes';

export const updateSearchResult = value => ({
  type: types.UPDATE_SEARCH_RESULT,
  value,
});

export const updateFilter = value => ({
  type: types.UPDATE_FILTER,
  value,
});
