import { connect } from 'react-redux';
import TransactionStream from './transactionStream';
import WithStreamChunk from '../streamChunk';

import {
  getInitialTransactionStream as getInitialDataStream,
  getLastChunkOfTransactions as getLastChunk,
} from '../../../../transaction/actions';

export const mapStateToProps = state => ({
  dataChunk: state.transaction.transactionChunk,
});

const mapDispatchToProps = {
  getInitialDataStream,
  getLastChunk,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithStreamChunk(TransactionStream));
