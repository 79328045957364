import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { BLOCK_STREAM_CHUNK_UPDATE_TIMER } from '../../../../constants/general';

export default function WithStreamChunk(WrappedComponent) {
  class StreamChunk extends PureComponent {
    componentDidMount() {
      const { dataChunk, getLastChunk, getInitialDataStream } = this.props;

      setInterval(() => {
        getLastChunk();
      }, BLOCK_STREAM_CHUNK_UPDATE_TIMER);

      if (dataChunk.length === 0) {
        getInitialDataStream();
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
        />
      );
    }
  }

  StreamChunk.propTypes = {
    getLastChunk: PropTypes.func,
    getInitialDataStream: PropTypes.func,
    dataChunk: PropTypes.array,
  };

  return StreamChunk;
}
