import React from 'react';
import styles from '../swap.module.scss';

import Table from '../../../components/table/table';

const Transactions = () => (
  <div className={styles.transactions}>
    <Table
      columns={[
        'Block Hash',
        'Tx Hash',
        'Fee',
        'Tx Type',
        'Height',
      ]}
      rows={[]}
    />
  </div>
);

// TODO: value for rows to be pulled from redux state, still have to hear back
// from OneLedger on how they are sending back the txs for swaps. Current return
// object does not have any txn info.

export default Transactions;
