import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Search from './search';
import { getDefaultSearch } from './actions';

export const mapStateToProps = state => ({
  isDefaultSearchLoading: state.defaultSearch.isDefaultSearchLoading,
  response: state.defaultSearch.response,
});

const mapDispatchToProps = {
  getDefaultSearch,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Search);
