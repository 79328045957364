import React from 'react';
import PropTypes from 'prop-types';

// Import Assets
import logoSmall from '../../../../../img/logo-small.svg';

// Import styles
import styles from './accountResultCard.module.scss';
import { shortenHash } from '../../../../../utils/formatters';

const AccountResultCard = ({ style, item, type }) => (
  <div className={styles['block-result-card-container']}>
    <div className={styles['result-card-banner']}>
      <p className={styles.text}>{type}</p>
    </div>
    <div className={styles['result-card']} style={style}>
      <img className={styles.logo} src={logoSmall} alt="logo-small" />

      <div className={`${styles.wrapper} has-text-centered-touch`}>
        <div className={styles['validation-label']}>
          <p className={styles.label}>Account Address:</p>
          <p className={styles.value}>
            {shortenHash(item.accountKey ? item.accountKey : item.key)}
          </p>
        </div>
        <div className={styles['validation-content']}>
          <p className={styles.label}>Balance:</p>
          <p className={styles.value}>{`${item.balance}`}</p>
        </div>
      </div>
    </div>
  </div>
);

AccountResultCard.propTypes = {
  style: PropTypes.object,
  item: PropTypes.object,
  type: PropTypes.string,
};

export default AccountResultCard;
