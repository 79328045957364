import React, { Fragment, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// Import Libraries
import classNames from 'classnames';
import Animated from 'animated/lib/targets/react-dom';

// Import Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import styles from './dropdown.module.scss';

// Import Custom Hooks
import { usePopAnimation } from '../../services/animation';

const Dropdown = ({
  options,
  label,
  labelStyles,
  labelContainerStyles,
  labelHoverColor,
  itemHoverColor,
  onSelected,
}) => {
  // Declare Selected and Visibility State
  const [selectedOption, setSelected] = useState(options[0]);
  const [showDropdown, setVisibility] = useState(false);

  // Declare Animated State
  const animatedState = usePopAnimation(showDropdown);

  // A ref for the event handling
  const dropDownNode = useRef();

  // Function to select option
  const handleSelected = (e, option) => {
    // clicked inside the dropdown
    if (dropDownNode.current.contains(e.target)) {
      // clicked inside the dropdown, BUT NOT on
      // a particular option (just hide/show the dropdown), so no action is needed
      if (!option) return;

      setSelected(option);
      onSelected(option.value);
      setVisibility(false);
    }

    // clicked outside of the dropdown
    setVisibility(false);
  };

  // by passing [] into effect, you're telling React to run and
  // clean up it once (mount and unmount), because [] means that effect
  // does not depend on any other prop or state values
  useEffect(() => {
    document.addEventListener('mousedown', handleSelected);
    return () => {
      document.removeEventListener('mousedown', handleSelected);
    };
  }, []);

  // Render Options
  const renderOptions = () => {
    const optionsElements = options.map((option) => (
      <div
        key={option.value}
        className={`${styles.option} ${styles[`item-${itemHoverColor}`]}`}
        onClick={e => handleSelected(e, option)}
      >
        {option.label}
      </div>
    ));
    return optionsElements;
  };

  // Declare classNames
  const containerClassName = classNames(
    styles['dropdown-container'],
    styles[`label-${labelHoverColor}`],
    labelContainerStyles,
  );
  const labelClassName = classNames(styles['dropdown-label'], labelStyles);

  return (
    <Fragment>
      <div
        className={containerClassName}
        onClick={() => {
          setVisibility(!showDropdown);
        }}
      >
        {selectedOption && (<p className={labelClassName}>{`${label}${selectedOption.label}`}</p>) }
        <FontAwesomeIcon icon={faCaretDown} className={styles['carret-icon']} />
      </div>
      <div ref={dropDownNode} className={styles['dropdown-content']}>
        <Animated.div
          className={styles.options}
          style={{
            transform: [
              {
                scale: animatedState,
              },
            ],
            opacity: animatedState,
          }}
        >
          {renderOptions()}
        </Animated.div>
      </div>
    </Fragment>
  );
};

Dropdown.defaultProps = {
  label: '',
  labelHoverColor: 'primary',
  itemHoverColor: 'primary',
  labelStyles: '',
  labelContainerStyles: '',
};

Dropdown.propTypes = {
  label: PropTypes.string,
  labelHoverColor: PropTypes.oneOf(['primary', 'secondary']),
  itemHoverColor: PropTypes.oneOf(['primary', 'secondary']),
  labelStyles: PropTypes.string,
  labelContainerStyles: PropTypes.string,
  options: PropTypes.array.isRequired,
  onSelected: PropTypes.func,
};

export default Dropdown;
