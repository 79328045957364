import { connect } from 'react-redux';
import BlockStream from './blockStream';
import WithStreamChunk from '../streamChunk';

import {
  getInitialBlockStream as getInitialDataStream,
  getLastChunkOfBlocks as getLastChunk,
} from '../../../../block/actions';

export const mapStateToProps = state => ({
  dataChunk: state.block.blockChunk,
});

const mapDispatchToProps = {
  getInitialDataStream,
  getLastChunk,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithStreamChunk(BlockStream));
