import { connect } from 'react-redux';
import { updateActiveTab } from '../actions';
import Transactions from './transactions';
// Filter swaps since they are not ready yet

export const mapStateToProps = state => ({
  activeTab: state.account.transactions.activeTab,
  isLoading: state.account.transactions.isLoading,
  transactions: state.account.transactions.transactions.filter(
    txn => txn.type !== 'swap',
  ),
});

const mapDispatchToProps = {
  updateActiveTab,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Transactions);
