import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TableRow from './components/tableRow';
import Pagination from './components/pagination';

import styles from './table.module.scss';

class PaginationTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentRows: [],
    };

    this.onPageChanged = this.onPageChanged.bind(this);
  }

  onPageChanged(data) {
    const { rows: allRows } = this.props;
    const { currentPage, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentRows = allRows.slice(offset, offset + pageLimit);

    this.setState({ currentRows });
  }

  render() {
    const { currentRows } = this.state;
    const { columns, rows: allRows } = this.props;
    const totalRows = allRows.length;
    if (totalRows === 0) return null;

    return (
      <div className={styles['pagination-table']}>
        <div className={styles.container}>
          <table className="table">
            {columns && (
              <thead>
                <tr>
                  {columns.map((column, th) => (
                    <th key={th}>{column}</th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody>
              {currentRows.map((row, tr) => (
                <TableRow key={tr} row={row} columns={columns} />
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.pagination}>
          <Pagination
            totalRecords={totalRows}
            pageLimit={14}
            pageNeighbours={1}
            onPageChanged={this.onPageChanged}
          />
        </div>
      </div>
    );
  }
}

PaginationTable.displayName = 'PaginationTable';
PaginationTable.defaultProps = {
  rows: [],
};
PaginationTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array,
};

export default PaginationTable;
