import * as types from './actionTypes';
import { sumPaymentTxnsValues } from './actions';
import {
  STREAM_CHUNK_LIMIT,
  STREAM_LIMIT,
} from '../../constants/general';

export const defaultState = {
  txn: {},
  error: null,
  isLoading: false,
  transactionChunk: [],
  transactionStream: {
    data: [],
    error: null,
    isLoading: false,
    intervalID: 0,
  },
};

const transaction = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_TRANSACTION_START:
      return {
        ...state,
        ...{
          isLoading: true,
        },
      };
    case types.GET_TRANSACTION_SUCCESS:
      return {
        ...defaultState,
        ...{
          txn: action.response,
        },
      };
    case types.GET_TRANSACTION_ERROR:
      return {
        ...defaultState,
        ...{
          error: action.error,
        },
      };
    case types.GET_TRANSACTION_STREAM_START:
      return {
        ...state,
        ...{
          transactionStream: {
            ...state.transactionStream,
            isLoading: true,
          },
        },
      };
    case types.GET_TRANSACTION_STREAM_SUCCESS:
      return {
        ...state,
        ...{
          transactionStream: {
            ...state.transactionStream,
            data: action.response,
            isLoading: false,
          },
        },
      };
    case types.UPDATE_TRANSACTION_STREAM:
      return {
        ...state,
        ...{
          transactionStream: {
            ...state.transactionStream,
            data: [action.response, ...state.transactionStream.data],
          },
        },
      };
    case types.SET_INTERVAL_ID:
      return {
        ...state,
        ...{
          transactionStream: {
            ...state.transactionStream,
            intervalID: action.intervalID,
          },
        },
      };
    case types.GET_TRANSACTION_STREAM_ERROR:
      return {
        ...state,
        ...{
          transactionStream: {
            ...state.transactionStream,
            error: action.error,
          },
        },
      };
    case types.GET_LAST_CHUNK_OF_TRANSACTIONS: {
      const transactionChunk = state.transactionStream.data.slice(0, STREAM_CHUNK_LIMIT);

      return {
        ...state, transactionChunk,
      };
    }
    case types.NEW_TRANSACTION_RECEIVE: {
      const { data: prevTxData } = state.transactionStream;
      const nextTxData = [action.response, ...prevTxData];
      return {
        ...state,
        ...{
          transactionStream: {
            ...state.transactionStream,
            data: sumPaymentTxnsValues(nextTxData).slice(0, STREAM_LIMIT),
          },
        },
      };
    }
    default:
      return state;
  }
};

export default transaction;
