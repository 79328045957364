import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from '../table.module.scss';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';
const FIRST_PAGE = 'FIRST';
const LAST_PAGE = 'LAST';

const range = (from, to, step = 1) => {
  let i = from;
  const rangeArr = [];

  while (i <= to) {
    rangeArr.push(i);
    i += step;
  }

  return rangeArr;
};

class Pagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
    };

    this.totalPages = Math.ceil(props.totalRecords / props.pageLimit);
    this.pageLimit = props.pageLimit;
    this.totalRecords = props.totalRecords;
    this.pageNeighbours = props.pageNeighbours;

    this.handleClick = this.handleClick.bind(this);
    this.handleMoveLeft = this.handleMoveLeft.bind(this);
    this.handleMoveRight = this.handleMoveRight.bind(this);
  }

  componentDidMount() {
    this.gotoPage(1);
  }

  gotoPage(page) {
    const { onPageChanged = f => f } = this.props;
    const currentPage = Math.max(0, Math.min(page, this.totalPages));

    const paginationData = {
      currentPage,
      totalPages: this.totalPages,
      pageLimit: this.pageLimit,
      totalRecords: this.totalRecords,
    };

    this.setState({ currentPage }, () => onPageChanged(paginationData));
  }

  handleClick(page) {
    return evt => {
      evt.preventDefault();
      this.gotoPage(page);
    };
  }

  handleMoveLeft(evt) {
    evt.preventDefault();
    const { currentPage } = this.state;
    this.gotoPage(currentPage - 1);
  }

  handleMoveRight(evt) {
    evt.preventDefault();
    const { currentPage } = this.state;
    this.gotoPage(currentPage + 1);
  }

  fetchPageNumbers() {
    const {
      totalPages,
      pageNeighbours,
    } = this;
    const { currentPage } = this.state;

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = (pageNeighbours * 2) + 2;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = (totalPages - endPage) > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [FIRST_PAGE, LEFT_PAGE, ...extraPages, ...pages, totalPages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [1, ...pages, ...extraPages, RIGHT_PAGE, LAST_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [FIRST_PAGE, LEFT_PAGE, ...pages, RIGHT_PAGE, LAST_PAGE];
          break;
        }
      }

      return pages;
    }

    return range(1, totalPages);
  }

  render() {
    if (!this.totalRecords || this.totalPages === 1) return null;

    const { currentPage } = this.state;
    const pages = this.fetchPageNumbers();

    return (
      <Fragment>
        <div className={styles.paginator}>
          { pages.map((page, index) => {
            if (page === FIRST_PAGE) {
              return (
                <div key={index} className={styles.hexagon}>
                  <a className="page-link" href="#" aria-label="First" onClick={this.handleClick(1)}>
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">First</span>
                  </a>
                </div>
              );
            }

            if (page === LEFT_PAGE) {
              return (
                <div key={index} className={styles.hexagon}>
                  <a className="page-link" href="#" aria-label="Previous" onClick={this.handleMoveLeft}>
                    <span aria-hidden="true">{'<'}</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </div>
              );
            }

            if (page === RIGHT_PAGE) {
              return (
                <div key={index} className={styles.hexagon}>
                  <a className="page-link" href="#" aria-label="Next" onClick={this.handleMoveRight}>
                    <span aria-hidden="true">{'>'}</span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              );
            }

            if (page === LAST_PAGE) {
              return (
                <div key={index} className={styles.hexagon}>
                  <a className="page-link" href="#" aria-label="Last" onClick={this.handleClick(this.totalPages)}>
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Last</span>
                  </a>
                </div>
              );
            }

            return (
              <div key={index} className={`${styles.hexagon} ${currentPage === page ? styles.active : ''}`}>
                <a className="page-link" href="#" onClick={this.handleClick(page)}>{ page }</a>
              </div>
            );
          }) }

        </div>
      </Fragment>
    );
  }
}

Pagination.propTypes = {
  totalRecords: PropTypes.number,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.oneOf([0, 1, 2]),
  onPageChanged: PropTypes.func,
};

export default Pagination;
