import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Import Components
import { Flipper, Flipped } from 'react-flip-toolkit';
import { getStreamCard } from '../streamCard/helper';

// Import Animation Hooks
import { useSpringAnimation } from '../../../../services/animation';

// Import styles
import styles from './streamDisplay.module.scss';
import './streamDisplay.scss';

// Import constants
import { VIEW_TYPE, STREAM_CHUNK_LIMIT } from '../../../../constants/general';

const StreamDisplay = ({ title, data, type }) => {
  // Declare Animation
  const scaleAnimatedValue = useSpringAnimation(data);

  // Get Stream Card Component
  const StreamCard = getStreamCard(type);

  const loadingStyle = {
    transform: [
      { scale: scaleAnimatedValue },
      {
        translateX: scaleAnimatedValue.interpolate({
          inputRange: [0, 1],
          outputRange: ['200px', '0px'],
          extrapolate: 'clamp',
        }),
      },
    ],
    opacity: scaleAnimatedValue,
  };

  const shouldUseLoadingStyle = index => (index === 0 ? loadingStyle : {});

  const key = (k, t) => `stream-${k}-${t}`;

  // Slice Data
  const slicedData = data.slice(0, STREAM_CHUNK_LIMIT);

  const streamCards = slicedData.map((item, index) => {
    let itemKey;

    switch (type) {
      case VIEW_TYPE.BLOCK:
        itemKey = key(item.height, index === 0 ? 'validator' : 'block');
        break;
      case VIEW_TYPE.TRANSACTION:
        itemKey = key(item.hash, 'tx');
        break;
      case VIEW_TYPE.SWAP:
        itemKey = key(item.swapKeyHash, 'swap');
        break;
      default:
        itemKey = key(item.height || item.blockHeight, 'default');
    }

    return (
      <Fragment key={itemKey}>
        <Flipped flipId={itemKey} spring="veryGentle">
          <StreamCard
            item={item}
            index={index}
            style={shouldUseLoadingStyle(index)}
          />
        </Flipped>
        {index === slicedData.length - 1 ? (
          <div className={styles['padding-fix']} />
        ) : null}
      </Fragment>
    );
  });

  const flipKey = data[0] ? data[0] : { height: 0 };

  const flexColumns = `columns ${styles.flexColumns}`;

  const flipperStreamStyle = ` ${styles.stream}`;
  const flipperContainerStyle = `scrollable-container ${styles['hidden-container']}`;
  const streamNavigationPrev = ` ${styles['stream-navigation']} stream-navigation-prev `;
  const streamNavigationNext = ` ${styles['stream-navigation']} stream-navigation-next`;
  const scrollableNavigation = `column ${styles['scrollable-navigation']}`;

  return (
    <Fragment>
      <p className={styles['stream-title']}>{title}</p>
      <div className={flexColumns}>
        <div className={streamNavigationPrev}>
          <div className="prev" />
        </div>
        <div className={scrollableNavigation}>
          <div className={flipperContainerStyle}>
            <Flipper id="ourFlipper" className={flipperStreamStyle} flipKey={flipKey.height}>
              {streamCards}
            </Flipper>
          </div>
        </div>
        <div className={streamNavigationNext}>
          <div className="next" />
        </div>
      </div>
    </Fragment>
  );
};

StreamDisplay.displayName = 'Stream';
StreamDisplay.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.oneOf([
    VIEW_TYPE.BLOCK,
    VIEW_TYPE.SWAP,
    VIEW_TYPE.TRANSACTION,
  ]),
};

export default StreamDisplay;
