import React from 'react';
import PropTypes from 'prop-types';

// Import Assets
import arrow from '../../../../../img/arrowCurved.png';
import Link from '../../../../../components/link/link.container';
import { ROUTES } from '../../../../../constants/general';

// Import styles
import styles from './transactionResultCard.module.scss';

// Import Formatters
import { shortenHash } from '../../../../../utils/formatters';

const getTotalAmount = item => {
  const { recipients } = item;

  if (!recipients) {
    return 0;
  }

  const initialValue = 0;

  const result = recipients.reduce((accumulator, currentItem) => {
    const amountValue = currentItem.amount.toLowerCase().replace('olt', '');
    return accumulator + parseInt(amountValue, 10);
  }, initialValue);

  return result;
};

const renderRecipients = item => {
  const { recipients } = item;

  if (!recipients) {
    return '';
  }

  const result = recipients.map(currentItem => {
    const { account, amount } = currentItem;
    return (
      <div className={styles['recipients-card']}>
        <p className={styles.value}>
          <Link path={`${ROUTES.ACCOUNT}/${account}`}>{shortenHash(account)}</Link>
        </p>
        <p className={styles.amount}>{amount}</p>
      </div>
    );
  });

  return result;
};

const TransactionResultCard = ({ style, item, type }) => {
  // There might be multiple recipients (and , therefore transactions), so
  // we are calculating total for all of them
  const totalAmount = getTotalAmount(item);

  return (
    <div className={styles['transaction-result-card-container']}>
      <div className={styles['result-card-banner']}>
        <p className={styles.text}>{type}</p>
      </div>
      <div className={styles['result-card']} style={style}>
        <p className={styles['transaction-value']}>{totalAmount && `${totalAmount} OLT`}</p>
        <div className={styles.divider} />
        <div className={styles.content}>
          <div className={styles['transaction-content']}>
            <p className={styles.label}>Tx Hash:</p>
            <p className={styles.value}>{shortenHash(item.hash)}</p>
          </div>

          <div className={styles['address-content']}>
            <img className={styles.arrow} src={arrow} alt="curved-arrow" />
            <div className={styles.content}>
              <p className={styles.label}>From:</p>
              <p className={styles.value}>
                <Link path={`${ROUTES.ACCOUNT}/${item.from}`}>{shortenHash(item.from)}</Link>
              </p>
              <p className={styles.label}>To:</p>
              { item.type === 'payment' ? <p className={styles.label}>Multiple</p> : renderRecipients(item) }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TransactionResultCard.propTypes = {
  style: PropTypes.object,
  item: PropTypes.object,
  type: PropTypes.string,
};

export default TransactionResultCard;
