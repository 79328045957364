import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import SearchBar from './searchBar';
import { updateSearchResult, updateFilter } from './actions';

export const mapStateToProps = state => ({
  query: state.search.query,
  filter: state.search.filter,
});

const mapDispatchToProps = {
  updateSearchResult,
  updateFilter,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchBar);
