// Taken from: https://github.com/killtheliterate/observable-socket
import { Observable, fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';

function create(ws) {
  const ready = () => ws.readyState === 1;
  const send = msg => ws.send(msg);

  const readyToSend = new Promise(resolve => {
    if (ready()) {
      resolve(send);
    } else {
      fromEvent(ws, 'open')
        .pipe(take(1))
        .subscribe(() => resolve(send));
    }
  });

  const wsObservable = Observable.create(observer => {
    const msgSubscription = fromEvent(ws, 'message').subscribe(e => {
      observer.next(e);
    });

    const errSubscription = fromEvent(ws, 'error').subscribe(e => {
      observer.error(e);
    });

    const closeSubscription = fromEvent(ws, 'close').subscribe(_ => {
      observer.complete();
    });

    return function cleanup() {
      closeSubscription.unsubscribe();
      errSubscription.unsubscribe();
      msgSubscription.unsubscribe();
    };
  });

  return {
    up: msg => readyToSend.then(s => s(msg)),
    down: wsObservable,
  };
}

export default { create };
