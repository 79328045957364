import React, { Fragment } from 'react';
import { VIEW_TYPE } from '../../../../constants/general';
import BlockStreamCard from './blockStreamCard/blockStreamCard';
import SwapStreamCard from './swapStreamCard/swapStreamCard';
import TransactionStreamCard from './transactionStreamCard/transactionStreamCard';

export const getStreamCard = type => props => {
  switch (type) {
    case VIEW_TYPE.BLOCK:
      return <BlockStreamCard {...props} />;
    case VIEW_TYPE.SWAP:
      return <SwapStreamCard {...props} />;
    case VIEW_TYPE.TRANSACTION:
      return <TransactionStreamCard {...props} />;
    default:
      return <Fragment />;
  }
};
