import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../components/table/table';

const TransactionsTable = ({ transactions }) => (
  <Table
    columns={['Tx Hash', 'Type', 'Value', 'Fee', 'From', 'To', 'Height']}
    rows={transactions}
  />
);

TransactionsTable.displayName = 'TransactionsTable';
TransactionsTable.propTypes = {
  transactions: PropTypes.array,
};

export default TransactionsTable;
