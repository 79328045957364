import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'rc-progress';
import Animated from 'animated/lib/targets/react-dom';

// Import Assets
import linkIcon from '../../../../../img/link.svg';
import arrow from '../../../../../img/arrowCurved.png';

// Import styles
import styles from './swapStreamCard.module.scss';
import colors from '../../../../../css/_colors.scss';

// Import Formatters
import { shortenHash } from '../../../../../utils/formatters';

const SwapStreamCard = ({
  style, item, ...restProps
}) => (
  <div className={styles['swap-stream-card-container']} {...restProps}>
    <Animated.div className={styles['stream-card']} style={style}>
      <p className={styles.status}>{item.status}</p>
      <Line
        className={styles['progress-bar']}
        percent="10"
        strokeWidth="2.5"
        trailWidth="2.5"
        strokeColor={colors.lightGreenishBlue}
        trailColor={colors.dirtyBlue}
        strokeLinecap="square"
      />
      <div className={styles.content}>
        <div className={styles['activity-content']}>
          <p className={styles.label}>Acticity ID:</p>
          <p className={styles.value}>{shortenHash(item.hash)}</p>
        </div>

        <div className={styles['address-content']}>
          <img className={styles.arrow} src={arrow} alt="curved-arrow" />
          <div className={styles.content}>
            <p className={styles.label}>From:</p>
            <p className={styles.value}>{shortenHash(item.party.address)}</p>
            <p className={styles.label}>To:</p>
            <p className={styles.value}>{shortenHash(item.counterParty.address)}</p>
          </div>
        </div>
      </div>
    </Animated.div>
    <Animated.img style={style} src={linkIcon} alt="block-link" />
  </div>
);

SwapStreamCard.propTypes = {
  style: PropTypes.object,
  item: PropTypes.object,
};
export default SwapStreamCard;
