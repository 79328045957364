import React from 'react';
import PropTypes from 'prop-types';
import styles from './card.module.scss';

import Header from './components/header';

const Card = ({
  size, header, content, className, contentClassName,
}) => (
  <div className={`${styles.card} ${styles[size]} ${className}`}>
    <div className={`${styles.header}`}>{header}</div>
    <div className={`${styles.content} ${contentClassName}`}>{content}</div>
  </div>
);

Card.Header = Header;

Card.displayName = 'Card';
Card.defaultProps = {
  size: 'regular',
  className: '',
  contentClassName: '',
};
Card.propTypes = {
  header: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
  size: PropTypes.oneOf(['tiny', 'small', 'regular', 'large']),
  className: PropTypes.string,
  contentClassName: PropTypes.string,
};

export default Card;
