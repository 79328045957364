import Home from './home.container';
import { ROUTES } from '../../constants/general';

export default [
  {
    name: 'home',
    path: ROUTES.HOME,
    isSecure: false,
    component: Home,
  },
];
