import Search from './search.container';

export default [
  {
    name: 'search',
    path: '/search/:query',
    isSecure: false,
    component: Search,
  },
];
