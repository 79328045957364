import Account from './account.container';
import { ROUTES } from '../../constants/general';

export default [
  {
    name: ROUTES.ACCOUNT,
    path: `${ROUTES.ACCOUNT}/:accountKey`,
    isSecure: false,
    component: Account,
  },
];
