import React from 'react';
import { ROUTES } from '../../constants/general';
import { shortenHash } from '../../utils/formatters';
import Link from '../link/link.container';

const getTxRoute = item => {
  switch (item.type) {
    case 'swap':
      return ROUTES.SWAP;
    case 'send':
    case 'payment':
    default:
      return ROUTES.TRANSACTION;
  }
};

export const getItem = (columnType, item) => {
  if (item === '') {
    return item;
  }
  switch (columnType) {
    case 'Tx Hash':
    case 'Hash':
      return <Link path={`${getTxRoute(item)}/${item}`}>{shortenHash(item)}</Link>;
    case 'From':
    case 'To':
      return <Link path={`${ROUTES.ACCOUNT}/${item}`}>{shortenHash(item)}</Link>;
    case 'Block':
      return <Link path={`${ROUTES.BLOCK}/${item}`}>{shortenHash(item)}</Link>;
    default:
      return item;
  }
};
