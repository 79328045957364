import React from 'react';
import PropTypes from 'prop-types';

// Import Assets
import logoSmall from '../../../../../img/logo-small.svg';

// Import styles
import styles from './blockResultCard.module.scss';

const BlockResultCard = ({ style, item, type }) => (
  <div className={styles['block-result-card-container']}>
    <div className={styles['result-card-banner']}>
      <p className={styles.text}>{type}</p>
    </div>
    <div className={styles['result-card']} style={style}>
      <img className={styles.logo} src={logoSmall} alt="logo-small" />

      <div>
        <p className={styles.label}>Block ID:</p>
        <p className={styles.value}>{item.height}</p>
      </div>

      <div className={styles['validation-content']}>
        <p className={styles.value}>
          {`${item.numTxs} ${
            item.numTxs === 0 ? 'transaction' : 'transactions'
          }`}
        </p>
      </div>
    </div>
  </div>
);

BlockResultCard.propTypes = {
  style: PropTypes.object,
  item: PropTypes.object,
  type: PropTypes.string,
};

export default BlockResultCard;
