import React from 'react';
import PropTypes from 'prop-types';
import styles from '../account.module.scss';

import Card from '../../../components/card/card';
// import Header from './accountInfo/header';
import Details from './accountInfo/details';

const AccountInfo = ({ info: { isLoading, accountData } }) => (
  <div className={` container ${styles.header}`}>
    <Card
      header={<Card.Header hash={!isLoading && accountData ? accountData.accountKey : ''} title="Account ID" columns="two" />}
      content={<Details isLoading={isLoading} balance={accountData ? accountData.balance : ''} />}
    />
  </div>
);

AccountInfo.displayName = 'AccountInfo';
AccountInfo.propTypes = {
  info: PropTypes.shape({
    isLoading: PropTypes.bool,
    accountData: PropTypes.object,
  }),
};

export default AccountInfo;
