import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './link.module.scss';

const LinkWrapper = ({ path, children }) => (
  <Link className={styles.link} to={path}>
    {children}
  </Link>
);

LinkWrapper.displayName = 'Link';
LinkWrapper.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default LinkWrapper;
