import React from 'react';
import PropTypes from 'prop-types';
import Animated from 'animated/lib/targets/react-dom';

// Import Assets
import arrow from '../../../../../img/arrowCurved.png';
import Link from '../../../../../components/link/link.container';
import { ROUTES } from '../../../../../constants/general';

// Import styles
import styles from './transactionStreamCard.module.scss';

// Import Formatters
import { shortenHash } from '../../../../../utils/formatters';

const TransactionStreamCard = ({ style, item, ...restProps }) => {
  const restPropsFiltered = Object.keys(restProps)
    .filter(k => k !== 'lastElement')
    .reduce((acc, key) => {
      acc[key] = restProps[key];
      return acc;
    }, {});
  return (
    <div
      className={styles['transaction-stream-card-container']}
      {...restPropsFiltered}
    >
      <Animated.div className={styles['stream-card']} style={style}>
        <p className={styles['transaction-value']}>
          {/* TODO: Only supports send transactions, ass soon as a new
          transaction type is chosen, return a different */}
          {`${item.recipients[0].amount}`}
        </p>
        <div className={styles.divider} />
        <div className={styles.content}>
          <div className={styles['transaction-content']}>
            <p className={styles.label}>Tx Hash:</p>
            <p className={styles.value}>
              <Link path={`${ROUTES.TRANSACTION}/${item.hash}`}>
                {shortenHash(item.hash)}
              </Link>
            </p>
          </div>

          <div className={styles['address-content']}>
            <img className={styles.arrow} src={arrow} alt="curved-arrow" />
            <div className={styles.content}>
              <p className={styles.label}>From:</p>
              <p className={styles.value}>
                <Link path={`${ROUTES.ACCOUNT}/${item.from}`}>
                  {shortenHash(item.from)}
                </Link>
              </p>
              <p className={styles.label}>To:</p>
              {item.type === 'payment' ? (
                <p className={styles.label}>Multiple</p>
              ) : (
                <p className={styles.value}>
                  <Link
                    path={`${ROUTES.ACCOUNT}/${item.recipients[0].account}`}
                  >
                    {shortenHash(item.recipients[0].account)}
                  </Link>
                </p>
              )}
            </div>
          </div>
        </div>
      </Animated.div>
    </div>
  );
};

TransactionStreamCard.propTypes = {
  style: PropTypes.object,
  item: PropTypes.object,
};

export default TransactionStreamCard;
