import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

// Import Components
import Card from '../../components/card/card';
import CardContent from './components/cardContent';
import { convertNumberToString } from '../../utils/formatters';

// Import Styles
import styles from './kpiCards.module.scss';

class KpiCards extends Component {
  constructor(props) {
    super(props);

    props.getKpiMetrics();
  }

  render() {
    const {
      data: { blocksPerSecond, numOfValidatorNodes, txPerSecond },
      isLoading,
      blockChunk,
    } = this.props;

    const blockHeight =
      blockChunk.length === 0 ? '' : blockChunk[0].height;

    const blocksKpiValue = blocksPerSecond ?
      convertNumberToString(blocksPerSecond * 60, 1) : 0;

    const txKpiValue = txPerSecond ?
      convertNumberToString(txPerSecond * 60, 1) : 0;

    const kpiCardStyle = ` column ${styles['kpi-card']}`;
    const flexColumns = `columns ${styles.flexColumns}`;

    return (
      <Fragment>
        <p className={styles.kpiTitle}>Performance</p>
        <div className="columns">
          <div className="column">
            <div className={flexColumns}>
              <Card
                className={kpiCardStyle}
                header={<Card.Header title="Blocks" columns="one" />}
                content={
                  <CardContent
                    kpiValue={blocksKpiValue}
                    kpiUnit="/m"
                    isLoading={isLoading}
                  />
                }
                size="tiny"
              />
              <Card
                className={kpiCardStyle}
                header={<Card.Header title="Transactions" columns="one" />}
                content={
                  <CardContent
                    kpiValue={txKpiValue}
                    kpiUnit="/m"
                    isLoading={isLoading}
                  />
                }
                size="tiny"
              />
            </div>
          </div>
          <div className="column">
            <div className={flexColumns}>
              <Card
                className={kpiCardStyle}
                header={<Card.Header title="Validator Nodes" columns="one" />}
                content={
                  <CardContent kpiValue={numOfValidatorNodes} isLoading={isLoading} />
                }
                size="tiny"
              />
              <Card
                className={kpiCardStyle}
                header={<Card.Header title="Block Height" columns="one" />}
                content={<CardContent kpiValue={blockHeight} isLoading={isLoading} />}
                size="tiny"
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

KpiCards.propTypes = {
  data: PropTypes.object,
  blockChunk: PropTypes.array,
  isLoading: PropTypes.bool,
  getKpiMetrics: PropTypes.func,
};

export default KpiCards;
