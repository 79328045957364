import * as types from './actionTypes';
import api from '../../config/api';
import { TRANSACTIONS_API_TYPE, CHRONOS_NETWORK } from '../../config/constants';

const getTransactionStart = () => ({
  type: types.GET_TRANSACTION_START,
});
const getTransactionSuccess = response => ({
  type: types.GET_TRANSACTION_SUCCESS,
  response,
});
const getTransactionError = error => ({
  type: types.GET_TRANSACTION_ERROR,
  error,
});

const getTransactionInfo = hash => async dispatch => {
  dispatch(getTransactionStart());
  try {
    if (!hash) return dispatch(getTransactionError('hash must be defined'));
    const response = await api(
      TRANSACTIONS_API_TYPE,
      CHRONOS_NETWORK,
    ).txsTxIDGet(hash);
    return dispatch(getTransactionSuccess(response));
  } catch (e) {
    return dispatch(getTransactionError(e));
  }
};

export const getTransaction = fallback => async dispatch => {
  const { hash } = fallback;
  return dispatch(getTransactionInfo(hash));
};

const getTransactionStreamStart = () => ({
  type: types.GET_TRANSACTION_STREAM_START,
});
const getTransactionStreamSuccess = response => ({
  type: types.GET_TRANSACTION_STREAM_SUCCESS,
  response,
});
const getTransactionStreamError = error => ({
  type: types.GET_TRANSACTION_STREAM_ERROR,
  error,
});

// Sums up Payment type OLT transactions for Transaction Stream
export const sumPaymentTxnsValues = response =>
  response.map(tx => {
    if (tx.type !== 'payment') {
      return tx;
    }

    const txValue = tx.recipients.reduce((acc, recipient) => {
      const fvalue = recipient.amount.value.split(' ')[0];
      return acc + parseFloat(fvalue);
    }, 0);

    return {
      ...tx,
      currency: tx.recipients[0].amount.currency,
      amount: { value: txValue.toString() },
    };
  });

export const getLastChunkOfTransactions = () => ({
  type: types.GET_LAST_CHUNK_OF_TRANSACTIONS,
});

export const getInitialTransactionStream = () => async dispatch => {
  dispatch(getTransactionStreamStart());
  try {
    // End-point Missing from API
    const rawResponse = await api(
      TRANSACTIONS_API_TYPE,
      CHRONOS_NETWORK,
    ).txsLatestGet();
    if (rawResponse) {
      const response = sumPaymentTxnsValues(rawResponse);

      const res = dispatch(getTransactionStreamSuccess(response));
      dispatch(getLastChunkOfTransactions());

      return res;
    }
    return dispatch(getTransactionStreamError());
  } catch (e) {
    const error = {
      msg: e.message,
      stack: e.stack || {},
      err: JSON.stringify(e),
    };
    return dispatch(getTransactionStreamError(error));
  }
};

export const clearTransactionStreamInterval = () => (__, getState) => {
  const {
    transaction: {
      transactionStream: { intervalID },
    },
  } = getState();

  clearInterval(intervalID);
};

export const receiveTx = tx => ({
  type: types.NEW_TRANSACTION_RECEIVE,
  response: tx,
});
