import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { responsiveStoreEnhancer } from 'redux-responsive';
import createReducer from './reducers';

let store;
const history =
  process.env.NODE_ENV === 'test'
    ? createMemoryHistory()
    : createBrowserHistory();

export const configureStore = () => {
  const reducers = createReducer(history);

  const middlewares = [].concat(
    [thunk, routerMiddleware(history)],
    process.env.NODE_ENV === 'developments' ? [createLogger()] : [],
  );

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancer = composeEnhancers(
    applyMiddleware(...middlewares),
    responsiveStoreEnhancer,
  );

  store = createStore(reducers, enhancer);
  store.asyncReducers = {};

  return {
    store,
    history,
  };
};
