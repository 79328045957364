import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './account.module.scss';

import AccountTransactions from './components/transactions.container';
import AccountInfo from './components/accountInfo.container';

class Account extends Component {
  constructor(props) {
    super(props);
    props.getAccount(props.match.params);
  }

  componentDidUpdate(prevProps) {
    const { getAccount, match: { params: currentParams } } = this.props;
    const { match: { params: { accountKey } } } = prevProps;

    if (currentParams.accountKey !== accountKey) {
      getAccount(currentParams);
    }
  }

  render() {
    return (
      <section className={` section ${styles.account}`}>
        <AccountInfo />
        <AccountTransactions />
      </section>
    );
  }
}

Account.displayName = 'Account';
Account.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  getAccount: PropTypes.func,
};

export default Account;
