import React from 'react';
import PropTypes from 'prop-types';
import styles from './button.module.scss';
import './button.scss';

const Button = ({
  size,
  label,
  className,
  color,
  disabled,
  onClick,
}) => (
  <button
    type="button"
    className={`${styles.button} ${disabled ? styles.disabled : ''} ${styles[color]} ${styles[size]} ${className}`}
    onClick={onClick}
  >
    {label}
  </button>
);

Button.displayName = 'Button';
Button.defaultProps = {
  label: '',
  className: '',
  color: 'turquoise',
  disabled: false,
  size: 'regular',
};
Button.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf(['turquoise']),
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'regular', 'large']),
  onClick: PropTypes.func,
};

export default Button;
