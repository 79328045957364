import React from 'react';
import PropTypes from 'prop-types';
import { getItem } from '../helper';

const TableRow = ({ row, columns }) => (
  <tr>
    {row.map((item, td) => (
      <td data-label={columns[td]} key={td}>
        {getItem(columns[td], item)}
      </td>
    ))}
  </tr>
);

TableRow.propTypes = {
  row: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

export default TableRow;
