import * as types from './actionTypes';
import api from '../../config/api';
import { ACCOUNTS_API_TYPE, CHRONOS_NETWORK } from '../../config/constants';

export const updateActiveTab = tab => ({
  type: types.UPDATE_ACTIVE_TAB,
  tab,
});

const getAccountInfoStart = () => ({
  type: types.GET_ACCOUNT_INFO_START,
});
const getAccountInfoSuccess = response => ({
  type: types.GET_ACCOUNT_INFO_SUCCESS,
  response,
});
const getAccountInfoError = error => ({
  type: types.GET_ACCOUNT_INFO_ERROR,
  error,
});

const getAccountInfo = address => async dispatch => {
  dispatch(getAccountInfoStart());
  try {
    const response = await api(ACCOUNTS_API_TYPE, CHRONOS_NETWORK).accountsAddressGet(address);
    return dispatch(getAccountInfoSuccess(response));
  } catch (e) {
    return dispatch(getAccountInfoError(e));
  }
};

const getAccountTransactionsStart = () => ({
  type: types.GET_ACCOUNT_TRANSACTIONS_START,
});
const getAccountTransactionsSuccess = response => ({
  type: types.GET_ACCOUNT_TRANSACTIONS_SUCCESS,
  response,
});
const getAccountTransactionsError = error => ({
  type: types.GET_ACCOUNT_TRANSACTIONS_ERROR,
  error,
});

const getAccountTransactions = address => async dispatch => {
  dispatch(getAccountTransactionsStart());
  try {
    const response = await api(ACCOUNTS_API_TYPE, CHRONOS_NETWORK).accountsAddressTxsGet(address);
    return dispatch(getAccountTransactionsSuccess(response.txs));
  } catch (e) {
    return dispatch(getAccountTransactionsError(e));
  }
};

// eslint-disable-next-line no-unused-vars
export const getAccount = (fallback) => async (dispatch, getState) => {
  const address = fallback.accountKey;

  dispatch(getAccountInfo(address));
  return dispatch(getAccountTransactions(address));
};
