import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './swap.module.scss';

import Info from './components/info.container';
import Transactions from './components/transactions';
import SwapSteps from './components/swapSteps';

class Swap extends Component {
  constructor(props) {
    super(props);
    const {
      getSwap,
      match: { params },
    } = props;

    getSwap(params);
  }

  componentDidUpdate(prevProps) {
    const {
      getSwap,
      match: { params: currentParams },
    } = this.props;
    const {
      match: {
        params: { swapKeyHash },
      },
    } = prevProps;

    if (currentParams.swapKeyHash !== swapKeyHash) {
      getSwap(currentParams);
    }
  }

  render() {
    const {
      swap: { swapStatus },
    } = this.props;
    return (
      <div className={styles.swap}>
        <SwapSteps current={swapStatus} />
        <Info />
        <Transactions />
      </div>
    );
  }
}

Swap.displayName = 'Swap';
Swap.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  getSwap: PropTypes.func,
  swap: PropTypes.object,
};

export default Swap;
